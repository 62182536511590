/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import NewEntity from "./EntityList/NewEntity";
import Entities from "./EntityList/Entities";
import NewEntityType from "./EntityTypes/NewEntityType";
import EntityTypes from "./EntityTypes/EntityTypes";

const Vendors = ({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: string;
  setSelectedTab: (selectedTab: string) => void;
}) => {
  useEffect(() => {
    setSelectedTab("entity types");
  }, []);

  return (
    <section className="flex flex-col flex-grow gap-5">
      <header className="flex items-center justify-between gap-10 text-b1-reg">
        <nav className="flex flex-wrap items-center">
          {["entity types", "entities"].map((tab) => {
            return (
              <button
                key={tab}
                className={`px-8 py-2 text-center capitalize ${
                  selectedTab === tab
                    ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                    : "dark:text-gray-500 dark:hover:text-white"
                }`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </button>
            );
          })}
        </nav>
        {selectedTab === "entity types" ? <NewEntityType /> : <NewEntity />}
      </header>
      {selectedTab === "entity types" ? (
        <EntityTypes />
      ) : selectedTab === "entities" ? (
        <Entities />
      ) : null}
    </section>
  );
};

export default Vendors;
