/* eslint-disable react-hooks/exhaustive-deps */
import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { SearchFrameworkControls } from "../../../../services/erc/controls/framework-controls";

const Filters = ({
  filters,
  setFilters,
}: {
  filters: any;
  setFilters: any;
}) => {
  const [query, setQuery] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");

  const search = SearchFrameworkControls();

  useEffect(() => {
    if (selectedType !== "")
      search.mutate({
        type: selectedType,
        query: "",
      });
  }, [selectedType]);

  return (
    <section className="flex flex-wrap items-center gap-5">
      {Object.keys(filters).map((type) => {
        return (
          <Popover key={type} className="relative text-b2-reg">
            <Popover.Button>
              <article
                className="flex items-center gap-2 px-4 py-2 capitalize dark:bg-gray-900 border dark:border-gray-700 divide-x dark:divide-gray-700 rounded"
                onClick={() => {
                  setSelectedType(type);
                  setQuery("");
                }}
              >
                <h4>{type.replaceAll("_", " ")}</h4>
                <FontAwesomeIcon
                  icon={faFilter}
                  className="pl-2 dark:text-blue-600"
                />
              </article>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute left-0 top-12 z-10">
                {({ close }) => (
                  <section className="grid gap-3 p-4 w-[20rem] max-h-[30rem] dark:bg-gray-700 black-shadow overflow-auto scrollbar rounded-lg">
                    <article className="flex items-center gap-2 px-4 dark:bg-gray-800 border dark:border-gray-700 rounded-sm">
                      <FontAwesomeIcon icon={faSearch} />
                      <input
                        type="input"
                        autoComplete="off"
                        spellCheck="false"
                        placeholder="Search"
                        value={query}
                        onChange={(e) => {
                          setQuery(e.target.value);
                          search.mutate({
                            type: selectedType,
                            query: e.target.value,
                          });
                        }}
                        className="w-full border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
                      />
                    </article>
                    {search.data ? (
                      search.data.length > 0 ? (
                        <ul className="flex flex-col flex-grow gap-1 h-full overflow-auto scrollbar">
                          <article className="flex items-center gap-5 divide-x dark:divide-checkbox">
                            <button
                              disabled={
                                filters[type].length === search.data.length
                              }
                              className="dark:disabled:text-filter dark:hover:text-checkbox/60 duration-100"
                              onClick={() =>
                                setFilters({
                                  ...filters,
                                  [type]: search.data,
                                })
                              }
                            >
                              Select All
                            </button>
                            <button
                              disabled={filters[type].length === 0}
                              className="pl-5 dark:disabled:text-filter dark:hover:text-checkbox/60 duration-100"
                              onClick={() => {
                                setFilters({ ...filters, [type]: [] });
                                setQuery("");
                              }}
                            >
                              Deselect All
                            </button>
                          </article>
                          {search.data.map((value: string) => {
                            const included = filters[type].includes(value);
                            return (
                              <li
                                key={value}
                                className="flex items-start gap-2 w-full"
                              >
                                <input
                                  type="checkbox"
                                  className="form-checkbox w-4 h-4 mt-1 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                                  checked={included}
                                  onChange={() => {
                                    if (included)
                                      setFilters({
                                        ...filters,
                                        [type]: filters[type].filter(
                                          (curVal: string) => curVal !== value
                                        ),
                                      });
                                    else
                                      setFilters({
                                        ...filters,
                                        [type]: [...filters[type], value],
                                      });
                                  }}
                                />
                                <label htmlFor="" className="capitalize">
                                  {value}
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <p className="px-4 py-1">No results found</p>
                      )
                    ) : null}
                  </section>
                )}
              </Popover.Panel>
            </Transition>
          </Popover>
        );
      })}
    </section>
  );
};

export default Filters;
