import React, { useState } from "react";
import FileInput from "src/components/Input/FileInput";
import RadioInput from "src/components/Input/RadioInput";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import {
  AttachAttestEvidence,
  GetControlMetadata,
  UpdateState,
} from "src/services/erc/controls/controls";

const AttestationLifecycleFlow = ({ controlID }: { controlID: string }) => {
  const [show, setShow] = useState<string>("");
  const [inputs, setInputs] = useState<any>({
    control_implemented: "",
    file: "",
    notes: "",
  });

  const { data: controlMetadata } = GetControlMetadata(controlID);
  const updateTransitionState = UpdateState(controlID);
  const attachTransitionStateEvidence = AttachAttestEvidence(controlID);

  const handleOnClose = () => {
    setShow("");
    setInputs({ control_implemented: "", file: "", notes: "" });
  };

  return (
    <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
      <h4 className="text-t1-semi">Attestation Lifecycle Flow</h4>
      <ol className="items-start sm:flex w-full">
        {["DRAFT", "ATTEST", "REVIEW", "MONITOR", "RETIRED"].map(
          (state, stateIdx) => {
            const curStateIdx = [
              "DRAFT",
              "ATTEST",
              "REVIEW",
              "MONITOR",
              "RETIRED",
            ].findIndex(
              (curState) =>
                controlMetadata?.state.toLowerCase() === curState.toLowerCase()
            );

            return (
              <li key={state} className="relative mb-6 sm:mb-0 w-full">
                <article className="flex items-center">
                  <button
                    disabled={
                      curStateIdx === 0 ||
                      stateIdx <= curStateIdx ||
                      stateIdx > curStateIdx + 1
                    }
                    className={`z-10 flex items-center justify-center w-28 h-28 bg-blue-100 rounded-full ring-0 ring-white ${
                      curStateIdx === stateIdx - 1
                        ? "dark:bg-blue-900"
                        : stateIdx <= curStateIdx
                        ? "dark:bg-green-500"
                        : "dark:bg-gray-700"
                    } sm:ring-8 dark:ring-gray-900 shrink-0`}
                    onClick={() => {
                      if (state === "ATTEST") {
                        setShow(state);
                        setInputs({ file: "" });
                      } else
                        updateTransitionState.mutate({
                          stateTo: state,
                        });
                    }}
                  >
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                      {state}
                    </h3>
                  </button>
                  <article className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-gray-700"></article>
                  <ModalLayout
                    showModal={show === state}
                    onClose={handleOnClose}
                  >
                    <section className="grid gap-5">
                      <h3 className="flex items-center gap-2 text-t1-semi">
                        Attach Evidence
                      </h3>
                      <RadioInput
                        label="Is Control Implemented"
                        keyName="is_control_implemented"
                        list={["Yes", "No", "Don't Know"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        required
                      />
                      {inputs.is_control_implemented === "Yes" && (
                        <>
                          <FileInput
                            label="Evidence"
                            keyName="file"
                            types={[
                              "png",
                              "svg",
                              "pdf",
                              "docx",
                              "csv",
                              "txt",
                              "xlsx",
                              "jpg",
                              "jpeg",
                            ]}
                            inputs={inputs}
                            setInputs={setInputs}
                          />
                          <RegularInput
                            label="Notes"
                            keyName="notes"
                            inputs={inputs}
                            setInputs={setInputs}
                            textarea
                          />
                        </>
                      )}
                      <article className="flex items-center place-content-end gap-5">
                        <button
                          className="black-button"
                          onClick={handleOnClose}
                        >
                          Cancel
                        </button>
                        <button
                          disabled={inputs.file === ""}
                          className="blue-button"
                          onClick={() => {
                            attachTransitionStateEvidence.mutate({
                              inputs: {
                                stateTo: state,
                                is_control_implemented:
                                  inputs.is_control_implemented,
                                file: inputs.file,
                                notes: inputs.notes,
                              },
                            });
                            handleOnClose();
                          }}
                        >
                          Done
                        </button>
                      </article>
                    </section>
                  </ModalLayout>
                </article>
                <article className="mt-3 sm:pe-8">
                  {state === "ATTEST" && (
                    <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                      Please attach evidence
                    </p>
                  )}
                </article>
              </li>
            );
          }
        )}
      </ol>
    </section>
  );
};

export default AttestationLifecycleFlow;
