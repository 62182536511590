/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
  AddPolicyGroup,
  GetPolicyGroups,
} from "src/services/regulation-policy/policy";
import { KeyStringVal } from "src/types/general";
import ModalLayout from "src/layouts/ModalLayout";
import RegularInput from "src/components/Input/RegularInput";

const CreatePolicyGroup = () => {
  const [inputs, setInputs] = useState({
    title: "",
    description: "",
  });
  const [valid, setValid] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);

  const { data: policyGroups } = GetPolicyGroups();
  const createPolicyGroup = AddPolicyGroup();

  const handleOnEnter = () => {
    if (
      policyGroups?.some(
        (policyGroup: KeyStringVal) =>
          policyGroup.title.toLowerCase().trim() ===
          inputs.title.toLowerCase().trim()
      )
    )
      setValid(false);
    else {
      setValid(true);
      createPolicyGroup.mutate({
        title: inputs.title,
        description: inputs.description,
      });
      handleOnClose();
    }
  };
  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="flex items-center gap-2 blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            title: "",
            description: "",
          });
          setValid(true);
        }}
      >
        <FontAwesomeIcon icon={faPlus} className="w-4 h-4" />
        <h4 className="relative w-max text-b2-reg">Add Policy Group</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 h-full overflow-auto scrollbar">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add Policy Group
          </h3>
          <RegularInput
            label="Title"
            keyName="title"
            inputs={inputs}
            setInputs={setInputs}
            valid={valid}
            setValid={setValid}
            required
          />
          <RegularInput
            label="Description"
            keyName="description"
            inputs={inputs}
            setInputs={setInputs}
          />
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.title === ""}
              className="blue-button"
              onClick={handleOnEnter}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default CreatePolicyGroup;
