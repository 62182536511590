import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ViewInFile from "src/pages/RegulationPolicy/Document/ViewInFile/ViewInFile";
import { GetGRCImages } from "src/services/grc";
import { KeyStringVal } from "src/types/general";

const Images = ({ versionID }: { versionID: string }) => {
  const { data: images } = GetGRCImages(versionID);
  return (
    <>
      {images ? (
        images.length > 0 ? (
          <ul className="flex flex-col flex-grow gap-5 pb-20">
            {images?.map((image: KeyStringVal) => (
              <li
                key={image.image_id}
                className="grid gap-3 p-4 dark:bg-gray-700 rounded-md"
              >
                <article className="flex items-center justify-between gap-5">
                  <button
                    className=""
                    onClick={() => {
                      let a = document.createElement("a");
                      a.href = image.image_signed_uri;
                      a.target = "_blank";
                      a.click();
                    }}
                  >
                    <FontAwesomeIcon icon={faDownload} /> Download as File
                  </button>
                  <article className="flex items-center gap-5">
                    <ViewInFile
                      generatedID={image.image_id}
                      section={image}
                      bbox={image.page_metadata}
                    />
                    <span className="w-max border-t-1 dark:border-yellow-500">
                      Page {image.page_num}
                    </span>
                  </article>
                </article>
                <img src={image.image_signed_uri} alt="preview" />
                {image.image_description && (
                  <article className="grid gap-2">
                    <h4 className="text-b1-reg">Uno's Analysis</h4>
                    <ReactMarkdown
                      className="px-6 py-4 min-w-full prose dark:prose-invert dark:bg-gray-800 rounded-xl"
                      remarkPlugins={[remarkGfm]}
                    >
                      {image.image_description}
                    </ReactMarkdown>
                  </article>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p>No images found</p>
        )
      ) : null}
    </>
  );
};

export default Images;
