import React from "react";
import { GetFrameworkControlMetadata } from "../../../../../services/erc/controls/framework-controls";

const FrameworkControlMetadata = ({
  frameworkControlID,
}: {
  frameworkControlID: string;
}) => {
  const { data: frameworkControlMetadata } =
    GetFrameworkControlMetadata(frameworkControlID);

  return (
    <>
      {frameworkControlMetadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            <h2 className="text-subt1-semi">{frameworkControlMetadata.name}</h2>
            <p>{frameworkControlMetadata.control_description}</p>
            <section className="grid grid-cols-5 gap-x-20 gap-y-10">
              {frameworkControlMetadata.control_id && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Control Id</h4>
                  <span className="text-b1-semi">
                    {frameworkControlMetadata.control_id}
                  </span>
                </article>
              )}
              {frameworkControlMetadata.sub_control_id && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Sub Control Id
                  </h4>
                  <span className="text-b1-semi">
                    {frameworkControlMetadata.sub_control_id}
                  </span>
                </article>
              )}
              {frameworkControlMetadata.category && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Category</h4>
                  <span className="text-b1-semi">
                    {frameworkControlMetadata.category}
                  </span>
                </article>
              )}
              {frameworkControlMetadata.sub_category && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Subcategory
                  </h4>
                  <span className="text-b1-semi">
                    {frameworkControlMetadata.sub_category}
                  </span>
                </article>
              )}
              {frameworkControlMetadata.framework_authority_short_name && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Framework Authority
                  </h4>
                  <span className="text-b1-semi">
                    {frameworkControlMetadata.framework_authority_short_name}
                  </span>
                </article>
              )}
              {frameworkControlMetadata.framework_short_name && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Framework Name
                  </h4>
                  <span className="text-b1-semi">
                    {frameworkControlMetadata.framework_short_name}
                  </span>
                </article>
              )}
              {frameworkControlMetadata.framework_version && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Framework Version
                  </h4>
                  <span className="text-b1-semi">
                    {frameworkControlMetadata.framework_version}
                  </span>
                </article>
              )}
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default FrameworkControlMetadata;
