import React, { createRef, useEffect } from "react";

const LoaderForm = ({ url, token }: { url: string; token: string }) => {
  const formElem = createRef() as any;

  useEffect(() => {
    if (formElem.current) formElem.current?.submit();
  }, [formElem]);

  return (
    <section className="flex flex-col flex-grow w-full min-h-full">
      <form
        ref={formElem}
        action={url}
        encType="multipart/form-data"
        method="post"
        target="collabora-online-viewer"
        id="collabora-submit-form"
      >
        <input
          name="access_token"
          value={token}
          type="hidden"
          id="access-token"
        />
        <input type="submit" value="" className="blue-button" />
      </form>
    </section>
  );
};

export default LoaderForm;
