/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Loader from "src/components/Loader/Loader";
import UpdateVendorGroup from "./UpdateVendorGroup";
import TablePagination from "src/components/General/TablePagination";
import { pageSize } from "src/constants/general";
import { GetVendorGroups } from "src/services/third-party-risk/vendors/vendor-groups";
import VendorGroupDetail from "./VendorGroupDetail/VendorGroupDetail";
import DeleteVendorGroup from "./DeleteVendorGroup";
import TableLayout from "src/layouts/TableLayout";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { initialSort, attributeColors } from "../../../../constants/general";
import { sortRows, handleSort, lastUpdatedAt } from "../../../../utils/general";

const VendorGroups = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedVendorGroup, setSelectedVendorGroup] = useState({});
  const [sort, setSort] = useState<Sort>(initialSort);

  const { data: vendorGroups, status: vendorGroupsStatus } =
    GetVendorGroups(pageNumber);

  const metadata = vendorGroups && JSON.parse(vendorGroups.header.metadata);
  const filteredMetadata = metadata?.required.filter((col: string) => {
    if (metadata.properties[col].hidden === "True") return null;
    return col;
  });
  const sortedRows = vendorGroups && sortRows(vendorGroups?.data, sort);

  const totalCount = vendorGroups?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <>
      {!sessionStorage.vendor_group_id ? (
        <section className="flex flex-col flex-grow gap-5">
          {vendorGroupsStatus === "loading" ? (
            <Loader />
          ) : vendorGroups?.data.length > 0 ? (
            <section className="flex flex-col flex-grow gap-5 pb-20">
              <TableLayout fullHeight>
                <thead className="sticky -top-1.5 dark:bg-gray-900">
                  <tr>
                    {filteredMetadata?.map((col: string) => {
                      const column = metadata.properties[col];
                      return (
                        <th
                          scope="col"
                          key={col}
                          className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                        >
                          <article className="capitalize flex gap-10 justify-between">
                            <h4 className="break-words">{column.title}</h4>
                            <button aria-label="sort">
                              <FontAwesomeIcon
                                icon={faSort}
                                className="mt-0.5 dark:text-gray-700"
                                onClick={() => handleSort(col, setSort)}
                              />
                            </button>
                          </article>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y dark:divide-gray-700">
                  {sortedRows?.map((row: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        data-test="table-row"
                        className="relative p-5 gap-3 cursor-pointer break-words dark:bg-gray-800 dark:hover:bg-gray-800/70 duration-100"
                        onClick={() => {
                          setSelectedVendorGroup(row);
                          sessionStorage.vendor_group_id = row.generated_id;
                          sessionStorage.vendor_group_name = row.name;
                        }}
                      >
                        {filteredMetadata?.map(
                          (col: string, colIndex: number) => {
                            const date = lastUpdatedAt(row.last_updated);

                            return (
                              <td
                                key={`${index}-${colIndex}`}
                                className="relative px-6 py-3 last:pr-16 text-left break-words"
                              >
                                <p
                                  className={`${
                                    attributeColors[
                                      String(row[col]).toLowerCase()
                                    ]
                                  }`}
                                >
                                  {col === "last_updated"
                                    ? date
                                    : String(row[col])}
                                </p>
                                {colIndex === filteredMetadata.length - 1 && (
                                  <article className="absolute top-1/3 right-5 flex items-center gap-3">
                                    <UpdateVendorGroup
                                      groupID={row.generated_id}
                                      vendorGroup={row}
                                    />
                                    <DeleteVendorGroup
                                      groupID={row.generated_id}
                                    />
                                  </article>
                                )}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </TableLayout>
              <TablePagination
                totalPages={totalPages}
                beginning={beginning}
                end={end}
                totalCount={totalCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            </section>
          ) : (
            <section className="flex items-center place-content-center gap-10 w-full h-full">
              <img
                src="/grc/third-party-risk-placeholder.svg"
                alt="vendor groups placeholder"
                className="w-40 h-40"
              />
              <article className="grid gap-3">
                <h4 className="text-t1-bold">Vendor Groups</h4>
                <h4>No vendor groups available</h4>
              </article>
            </section>
          )}
        </section>
      ) : (
        <VendorGroupDetail
          selectedVendorGroup={selectedVendorGroup}
          setSelectedPolicyGroup={setSelectedVendorGroup}
        />
      )}
    </>
  );
};

export default VendorGroups;
