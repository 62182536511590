/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import DatePicker from "react-datepicker";
import { forwardRef, LegacyRef } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { utcFormat } from "d3-time-format";
import {
  convertUTCToLocalDate,
  convertLocalToUTCDate,
  convertToMicrosec,
  convertToUTCString,
  convertToDate,
} from "../../utils/general";

const GeneralSnapshotDatepicker = ({
  label,
  keyName,
  inputs,
  setInputs,
  onlyFutureDate,
}: {
  label?: string;
  keyName: string;
  inputs: any;
  setInputs: (inputs: any) => void;
  onlyFutureDate?: boolean;
}) => {
  const DateInput = forwardRef(
    (
      { value, onClick }: any,
      ref: LegacyRef<HTMLButtonElement> | undefined
    ) => (
      <button
        onClick={onClick}
        ref={ref}
        type="button"
        className="min-w-full text-left text-b2-reg dark:text-white"
      >
        <p>
          {typeof inputs[keyName] === "number"
            ? convertToUTCString(inputs[keyName])
            : inputs[keyName] instanceof Date
            ? convertToUTCString(convertToMicrosec(inputs[keyName]))
            : "Select Time"}
        </p>
      </button>
    )
  );

  return (
    <section className="grid">
      {label && <h4 className="text-b2-reg">{label}</h4>}
      <article className="relative flex items-center px-3 py-1 w-full dark:bg-gray-900 rounded">
        <DatePicker
          selected={convertUTCToLocalDate(convertToDate(inputs[keyName]))}
          onChange={(date) =>
            setInputs({
              ...inputs,
              [keyName]: convertToMicrosec(convertLocalToUTCDate(date)),
            })
          }
          selectsStart
          filterDate={
            onlyFutureDate
              ? (date) => {
                  const timestamp = convertToMicrosec(date);
                  return timestamp > Date.now() * 1000;
                }
              : (date) => {
                  return true;
                }
          }
          startDate={convertUTCToLocalDate(convertToDate(inputs[keyName]))}
          showTimeSelect
          timeFormat="h:mm aa"
          timeIntervals={60}
          dateFormat="MMMM d, yyyy h:mm aa"
          placeholderText="From"
          nextMonthButtonLabel=">"
          previousMonthButtonLabel="<"
          wrapperClassName="datepicker"
          popperClassName="react-datepicker"
          customInput={<DateInput />}
          renderCustomHeader={({
            date,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => {
            return (
              <article className="flex items-center justify-between px-2 py-2 dark:text-white">
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  type="button"
                  className={`${
                    prevMonthButtonDisabled
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  } inline-flex text-xs font-medium shadow-sm dark:hover:bg-checkbox focus:outline-none rounded`}
                >
                  <ChevronLeftIcon className="w-5 h-5" />
                </button>
                <p className="text-sm">
                  {utcFormat("%b")(date)}{" "}
                  <span className="dark:text-checkbox">
                    {utcFormat("%Y")(date)}
                  </span>
                </p>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  type="button"
                  className={`${
                    nextMonthButtonDisabled
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  } inline-flex text-xs font-medium shadow-sm dark:hover:bg-checkbox focus:outline-none rounded`}
                >
                  <ChevronRightIcon className="w-5 h-5" />
                </button>
              </article>
            );
          }}
        />
      </article>
    </section>
  );
};

export default GeneralSnapshotDatepicker;
