import React, { useEffect } from "react";
import ReturnPage from "src/components/Button/ReturnPage";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import ControlBlueprintMetadata from "./ControlBlueprintMetadata";
import AssociateEntityTypeFilter from "../../../../../components/Filter/ERC/AssociateEntityTypeFilter";

const ControlBlueprint = () => {
  const parsed = parseURL();

  const blueprintID = String(parsed.control_blueprint_id) || "";

  useEffect(() => {
    sessionStorage.GRCCategory = "controls";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-10 p-4 pb-20 min-h-full w-full overflow-auto scrollbar">
        <article className="flex items-center gap-2">
          <ReturnPage />
          <h4 className="flex items-center gap-2 text-b3-reg">
            <span className="dark:text-gray-500">
              Entities, Risks, & Controls
            </span>{" "}
            <span>/</span>
            <span>Control Blueprints</span>
          </h4>
        </article>
        <ControlBlueprintMetadata blueprintID={blueprintID} />
        <AssociateEntityTypeFilter
          label="Associated Entity Types"
          blueprintID={blueprintID}
        />
      </main>
    </PageLayout>
  );
};

export default ControlBlueprint;
