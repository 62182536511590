import { useQuery, useMutation } from "react-query";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { queryClient } from "src/App";

const prefix = "grc/entitytypes";

export const GetEntityTypes = (
  pageNumber?: number,
  tags?: string[] | undefined,
  groups?: string[]
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-entity-types", pageNumber, tags, groups],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/getall`,
          pageNumber
            ? {
                page_number: pageNumber,
                page_size: pageSize,
              }
            : null,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const AddEntityType = () =>
  useMutation<any, unknown, any, string>(
    async ({ info, signal }: { info: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(`/api/${apiVersion}/${prefix}`, info, {
          signal,
        });
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-entity-types"]),
    }
  );

export const GetEntityTypeMetadata = (entityTypeID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-entity-type-metadata", entityTypeID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${entityTypeID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: entityTypeID !== "",
      keepPreviousData: false,
    }
  );

export const GetEntitiesFromEntityType = (
  entityTypeID: string,
  pageNumber: number
) =>
  useQuery<any, unknown, any, (string | number | undefined)[]>(
    ["get-entities-from-entity-type", entityTypeID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${entityTypeID}/members`,
          { page_number: pageNumber, page_size: pageSize },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: entityTypeID !== "",
      keepPreviousData: false,
    }
  );

export const AddEntityToEntityType = () =>
  useMutation<any, unknown, any, string>(
    async ({
      entityTypeID,
      entity,
      signal,
    }: {
      entityTypeID: string;
      entity: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${entityTypeID}/members/add`,
          entity,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-entities-from-entity-type"]);
        queryClient.invalidateQueries(["get-entities"]);
        queryClient.invalidateQueries(["get-entity-metadata"]);
      },
    }
  );

export const RemoveEntityFromEntityType = () =>
  useMutation<any, unknown, any, string>(
    async ({
      entityTypeID,
      entity,
      signal,
    }: {
      entityTypeID: string;
      entity: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${entityTypeID}/members/remove`,
          entity,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-entities-from-entity-type"]);
        queryClient.invalidateQueries(["get-entities"]);
        queryClient.invalidateQueries(["get-entity-metadata"]);
      },
    }
  );

export const GetBlueprintsForEntityType = (
  entityTypeID: string,
  pageNumber?: number,
  tags?: string[] | undefined,
  groups?: string[]
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-blueprints-for-entity-type", entityTypeID, pageNumber, tags, groups],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${entityTypeID}/blueprints`,
          pageNumber
            ? {
                page_number: pageNumber,
                page_size: pageSize,
              }
            : null,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: entityTypeID !== "",
      keepPreviousData: false,
    }
  );

export const AddBlueprintToEntityType = () =>
  useMutation<any, unknown, any, string>(
    async ({
      entityTypeID,
      blueprint,
      signal,
    }: {
      entityTypeID: string;
      blueprint: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${entityTypeID}/blueprints/add`,
          blueprint,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-blueprints-for-entity-type"]);
        queryClient.invalidateQueries(["get-entity-type-metadata"]);
        queryClient.invalidateQueries(["get-control-blueprints"]);
      },
    }
  );

export const RemoveBlueprintFromEntityType = () =>
  useMutation<any, unknown, any, string>(
    async ({
      entityTypeID,
      blueprint,
      signal,
    }: {
      entityTypeID: string;
      blueprint: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${entityTypeID}/blueprints/remove`,
          blueprint,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-blueprints-for-entity-type"]);
        queryClient.invalidateQueries(["get-entity-type-metadata"]);
        queryClient.invalidateQueries(["get-control-blueprints"]);
      },
    }
  );
