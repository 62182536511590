/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ToastLayout from "../../../../layouts/ToastLayout";
import { AddToControlBlueprints } from "src/services/erc/controls/framework-controls";

const AddToBlueprints = ({
  selectedFrameworks,
  setSelectedFrameworks,
}: {
  selectedFrameworks: string[];
  setSelectedFrameworks: (selectedFrameworks: string[]) => void;
}) => {
  const addToControlBlueprints = AddToControlBlueprints();

  const handleOnCloseConfirmation = () => {
    addToControlBlueprints.reset();
    setSelectedFrameworks([]);
  };

  return (
    <>
      <button
        className="blue-button"
        onClick={() =>
          addToControlBlueprints.mutate({
            selectedFrameworks,
          })
        }
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add to Control Blueprints</h4>
      </button>
      <ToastLayout
        showModal={addToControlBlueprints.status === "success"}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="Framework controls have been added to control blueprints"
      />
    </>
  );
};

export default AddToBlueprints;
