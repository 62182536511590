import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import { EditRiskBlueprint } from "src/services/erc/risks/risk-blueprints";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import AssessmentQuestionnaireFilter from "src/components/Filter/ERC/AssessmentQuestionnaireFilter";
import CheckboxInput from "src/components/Input/CheckboxInput";
import MultiUserInput from "src/components/Input/MultiUserInput";
import CategoryInput from "src/components/Input/CategoryInput";

const UpdateRiskBlueprint = ({
  riskBlueprintID,
  blueprint,
}: {
  riskBlueprintID: string;
  blueprint: any;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    description: "",
    threat: "",
    vulnerability: "",
    category: "",
    sub_category: "",
    classification: "",
    type: "",
    owner_same_as_entity_owner: true,
    owners: [],
    reviewers: [],
    approvers: [],
    treatment: "",
    treatment_plan: "",
    deadline: Date.now() * 1000,
    create_risks_automatically: true,
    inherent_risk_score: 0,
    inherent_risk_impact_level: "",
    inherent_risk_likelihood_level: "",
    assessment: "",
    additional_information: {},
    treatment_type: "MITIGATE",
    status: "DRAFT",
    treatment_status: "",
  });
  const [valid, setValid] = useState<boolean>(true);

  const editRiskBlueprint = EditRiskBlueprint(riskBlueprintID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: blueprint.name,
            description: blueprint.description,
            threat: blueprint.threat,
            vulnerability: blueprint.vulnerability,
            category: blueprint.category,
            sub_category: blueprint.sub_category,
            classification: blueprint.classification,
            type: blueprint.type,
            owner_same_as_entity_owner: blueprint.owner_same_as_entity_owner,
            owners: blueprint.owners || [],
            reviewers: blueprint.reviewers || [],
            approvers: blueprint.approvers || [],
            treatment: blueprint.treatment,
            treatment_plan: blueprint.treatment_plan,
            deadline: blueprint.deadline || Date.now() * 1000,
            create_risks_automatically: blueprint.create_risks_automatically,
            inherent_risk_score: blueprint.inherent_risk_score,
            inherent_risk_impact_level: blueprint.inherent_risk_impact_level,
            inherent_risk_likelihood_level:
              blueprint.inherent_risk_likelihood_level,
            assessment: blueprint.assessment,
            additional_information: blueprint.additional_information,
            treatment_type: blueprint.treatment_type,
            status: blueprint.status,
            treatment_status: blueprint.treatment_status,
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Edit Risk Blueprint</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 w-full h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Edit Risk Blueprint
          </h3>
          <section className="grid md:grid-cols-2 gap-5">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <RegularInput
              label="Classification"
              keyName="classification"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Type"
              keyName="type"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Category"
              keyName="category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Subcategory"
              keyName="sub_category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Threat"
              keyName="threat"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Vulnerability"
              keyName="vulnerability"
              inputs={inputs}
              setInputs={setInputs}
            />
            <MultiUserInput
              label="Owners"
              keyName="owners"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <AssessmentQuestionnaireFilter
              label="Assessment"
              keyName="assessment"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CheckboxInput
              label="Create Risks Automatically"
              keyName="create_risks_automatically"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CheckboxInput
              label="Owner Same as Entity Owner"
              keyName="owner_same_as_entity_owner"
              inputs={inputs}
              setInputs={setInputs}
            />
          </section>
          <section className="grid md:grid-cols-2 gap-5">
            <MultiUserInput
              label="Reviewers"
              keyName="reviewers"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <MultiUserInput
              label="Approvers"
              keyName="approvers"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <CategoryInput
              label="Treatment Type"
              keyName="treatment_type"
              list={["ACCEPT", "AVOID", "MITIGATE", "TRANSFER"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <GeneralSnapshotDatepicker
              label="Deadline"
              keyName="deadline"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CategoryInput
              label="Treatment Status"
              keyName="treatment_status"
              list={["Open", "Closed", "Resolved", "Pending"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <RegularInput
              label="Treatment Plan"
              keyName="treatment_plan"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CategoryInput
              label="Inherent Risk Impact Level"
              keyName="inherent_risk_impact_level"
              list={[
                "NEGLIGIBLE",
                "MINOR",
                "MODERATE",
                "SIGNIFICANT",
                "SEVERE",
              ]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <CategoryInput
              label="Inherent Risk Likelihood Level"
              keyName="inherent_risk_likelihood_level"
              list={[
                "VERY_UNLIKELY",
                "UNLIKELY",
                "POSSIBLE",
                "LIKELY",
                "VERY_LIKELY",
              ]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <CategoryInput
              label="Status"
              keyName="status"
              list={["DRAFT", "INACTIVE", "ACTIVE", "ARCHIVED"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
          </section>
          <RegularInput
            label="Description"
            keyName="description"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                editRiskBlueprint.mutate({
                  info: inputs,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UpdateRiskBlueprint;
