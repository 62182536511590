/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  GenerateTestPlan,
  GetControlMetadata,
} from "src/services/erc/controls/controls";

const TestPlan = ({ controlID }: { controlID: string }) => {
  const { data: controlMetadata } = GetControlMetadata(controlID);
  const generateTestPlan = GenerateTestPlan(controlID);

  return (
    <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
      <header className="flex items-center gap-5">
        <h4 className="text-t1-semi">Test Plan</h4>
        {!controlMetadata?.is_test_plan_generated && (
          <button
            className="blue-button"
            onClick={() => generateTestPlan.mutate({})}
          >
            Generate Test Plan
          </button>
        )}
      </header>
      {controlMetadata?.test_plan && (
        <ReactMarkdown
          className="px-6 py-4 min-w-full prose dark:prose-invert dark:bg-gray-800 rounded-xl"
          remarkPlugins={[remarkGfm]}
        >
          {controlMetadata?.test_plan}
        </ReactMarkdown>
      )}
    </section>
  );
};

export default TestPlan;
