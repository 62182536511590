import { useQuery, useMutation } from "react-query";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { KeyStringArrayVal } from "src/types/general";

const prefix = "grc/grc-controls/controls";

export const GetControls = (
  pageNumber: number,
  filters?: KeyStringArrayVal,
  tags?: string[] | undefined,
  groups?: string[]
) =>
  useQuery<
    any,
    unknown,
    any,
    (string | number | KeyStringArrayVal | string[] | undefined)[]
  >(
    ["get-controls", pageNumber, filters, tags, groups],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/getall`,
          {
            filter_criteria: filters,
            pager: {
              page_number: pageNumber,
              page_size: pageSize,
            },
            tags,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const AddControl = () =>
  useMutation<any, unknown, any, string>(
    async ({ info, signal }: { info: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(`/api/${apiVersion}/${prefix}`, info, {
          signal,
        });
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-controls"]),
    }
  );

export const EditControl = (controlID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ info, signal }: { info: any; signal: AbortSignal }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${prefix}/${controlID}`,
          info,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-control-metadata"]);
      },
    }
  );

export const GetControlMetadata = (controlID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-control-metadata", controlID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${controlID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: controlID !== "",
      keepPreviousData: false,
    }
  );

export const AssignTagToControl = (controlID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${controlID}/tag`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-controls"]);
        queryClient.invalidateQueries(["get-control-metadata"]);
        queryClient.invalidateQueries(["get-tags-for-control"]);
      },
    }
  );

export const RemoveTagFromControl = (controlID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${controlID}/untag`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-controls"]);
        queryClient.invalidateQueries(["get-control-metadata"]);
        queryClient.invalidateQueries(["get-tags-for-control"]);
      },
    }
  );

export const UpdateState = (controlID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ stateTo, signal }: { stateTo: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${controlID}/transition-state`,
          { state_to: stateTo },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-transition-state"]),
    }
  );

export const AttachAttestEvidence = (controlID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${controlID}/transition-state/attachment`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-transition-state"]),
    }
  );

export const GenerateTestPlan = (controlID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${controlID}/test-plan`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-control-metadata"]),
    }
  );

export const GetControlCoverage = (policyIDs: string[]) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-control-coverage", policyIDs],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/coverage`,
          { policy_ids: policyIDs },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: policyIDs?.length > 0,
      keepPreviousData: false,
    }
  );
