import React from "react";
import { attributeColors } from "../../../../../constants/general";
import {
  ArchiveRiskBlueprint,
  RestoreRiskBlueprint,
} from "src/services/erc/risks/risk-blueprints";

const ArchiveRestoreRiskBlueprint = ({
  riskBlueprintID,
  blueprint,
}: {
  riskBlueprintID: string;
  blueprint: any;
}) => {
  const archiveRiskBlueprint = ArchiveRiskBlueprint(riskBlueprintID);
  const restoreRiskBlueprint = RestoreRiskBlueprint(riskBlueprintID);

  return (
    <nav className="flex items-center gap-1 p-1 w-max dark:bg-gray-900 rounded-lg">
      {["active", "archive"].map((tab) => {
        return (
          <button
            key={tab}
            className={`px-4 py-1 text-center capitalize ${
              blueprint.status.toLowerCase() === tab
                ? `${attributeColors[blueprint.status]}`
                : "dark:text-gray-500 dark:hover:bg-gray-500/30 duration-100"
            } rounded-lg`}
            onClick={(e) => {
              e.stopPropagation();
              if (tab === "archive") archiveRiskBlueprint.mutate({});
              else restoreRiskBlueprint.mutate({});
            }}
          >
            {blueprint.status.toLowerCase() === "draft" && tab === "active"
              ? "activate"
              : blueprint.status.toLowerCase() === "archived" &&
                tab === "active"
              ? "recover"
              : tab}
          </button>
        );
      })}
    </nav>
  );
};

export default ArchiveRestoreRiskBlueprint;
