import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import { EditControl } from "../../../../../services/erc/controls/controls";
import UserInput from "../../../../../components/Input/UserInput";
import ControlBlueprintFilter from "../../../../../components/Filter/ERC/ControlBlueprintFilter";
import EntityFilter from "../../../../../components/Filter/ERC/EntityFilter";
import CheckboxInput from "src/components/Input/CheckboxInput";

const UpdateControl = ({
  controlID,
  control,
}: {
  controlID: string;
  control: any;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: control.name,
    control_reference_id: control.control_reference_id || "",
    reviewer: control.reviewer || "",
    same_as_entity_owner: control.same_as_entity_owner,
    grc_entity_id: control.grc_entity_id || "",
    control_blueprint_id: control.control_blueprint_id || "",
    owner: control.owner || "",
  });
  const [valid, setValid] = useState<boolean>(true);

  const editControl = EditControl(controlID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="px-4 py-2 text-b1-reg dark:bg-gray-900 dark:hover:bg-gray-900/70 duration-100 rounded-xl"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
        }}
      >
        <FontAwesomeIcon icon={faEdit} /> Edit
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Edit Control</h3>
          <section className="grid md:grid-cols-2 gap-5">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <EntityFilter
              label="Entity"
              keyName="grc_entity_id"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
            <ControlBlueprintFilter
              label="Control Blueprint"
              keyName="control_blueprint_id"
              inputs={inputs}
              setInputs={setInputs}
            />
            <UserInput
              label="Reviewer"
              keyName="reviewer"
              inputs={inputs}
              setInputs={setInputs}
              vertical
            />
            <RegularInput
              label="Control Reference Id"
              keyName="control_reference_id"
              inputs={inputs}
              setInputs={setInputs}
            />
          </section>
          <section className="grid grid-cols-2 gap-5">
            {!inputs.same_as_entity_owner && (
              <UserInput
                label="Owner"
                keyName="owner"
                inputs={inputs}
                setInputs={setInputs}
                vertical
              />
            )}
          </section>
          <CheckboxInput
            label="Same as entity owner"
            keyName="same_as_entity_owner"
            inputs={inputs}
            setInputs={setInputs}
          />
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === "" || inputs.grc_entity_id === ""}
              className="blue-button"
              onClick={() => {
                editControl.mutate({
                  info: inputs,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UpdateControl;
