/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { KeyStringVal } from "src/types/general";
import {
  AddEntityToEntityType,
  RemoveEntityFromEntityType,
} from "../../../services/erc/entities/entity-types";
import { GetEntities } from "../../../services/erc/entities/entities";

const AddEntityFilter = ({
  label,
  members,
  entityTypeID,
}: {
  label?: string;
  members: KeyStringVal[];
  entityTypeID: string;
}) => {
  const { data: entities } = GetEntities();
  const addEntityToEntityType = AddEntityToEntityType();
  const removeEntityToEntityType = RemoveEntityFromEntityType();

  return (
    <DropdownLayout
      label={label}
      value=""
      width="w-[10rem]"
      placeholder="Select Entity"
    >
      {entities?.data.map((entity: KeyStringVal, index: number) => {
        const included = members?.some(
          (member) => member.grc_entity_id === entity.grc_entity_id
        );
        if (entity.entity_type_id && entity.entity_type_id !== entityTypeID)
          return null;

        return (
          <button
            key={index}
            className="relative flex items-center gap-2 px-4 py-1 w-full break-words text-left dark:disabled:hover:bg-transparent dark:hover:bg-gray-600/70 duration-100"
            onClick={() => {
              if (included)
                removeEntityToEntityType.mutate({
                  entityTypeID: entityTypeID,
                  entity: [entity.grc_entity_id],
                });
              else
                addEntityToEntityType.mutate({
                  entityTypeID: entityTypeID,
                  entity: [entity.grc_entity_id],
                });
            }}
          >
            {included && <FontAwesomeIcon icon={faCheck} className="text-no" />}
            <p>{entity.name}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default AddEntityFilter;
