import React from "react";
import { convertToUTCShortString, getEmailFromID } from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetControlBlueprintMetadata } from "../../../../../services/erc/controls/control-blueprints";
import { GetAllUsers } from "../../../../../services/settings/users";
import { getCustomerID } from "../../../../../utils/general";

const ControlBlueprintMetadata = ({ blueprintID }: { blueprintID: string }) => {
  const customerID = getCustomerID();

  const { data: controlBlueprintMetadata } =
    GetControlBlueprintMetadata(blueprintID);
  const { data: allUsers } = GetAllUsers(customerID, false);

  const owner =
    allUsers?.find((user) => user.user_id === controlBlueprintMetadata?.owner)
      ?.user_email || "";

  return (
    <>
      {controlBlueprintMetadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="grid content-start gap-1">
              <h2 className="text-subt1-semi">
                {controlBlueprintMetadata.name}
              </h2>
              <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                <h4>
                  created by{" "}
                  {getEmailFromID(
                    allUsers,
                    controlBlueprintMetadata.created_by
                  )}
                </h4>
                <span>
                  at{" "}
                  {convertToUTCShortString(controlBlueprintMetadata.created_at)}
                </span>
              </article>
              <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                <h4>
                  last updated by{" "}
                  {getEmailFromID(
                    allUsers,
                    controlBlueprintMetadata.last_updated_by
                  )}
                </h4>
                <span>
                  at{" "}
                  {convertToUTCShortString(
                    controlBlueprintMetadata.last_updated_at
                  )}
                </span>
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-2 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[controlBlueprintMetadata.status.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[
                      controlBlueprintMetadata.status.toLowerCase()
                    ]
                  }`}
                >
                  {controlBlueprintMetadata.status}
                </span>
              </article>
              {controlBlueprintMetadata.create_controls_automatically_enabled && (
                <article
                  className={`flex items-center gap-1 ${attributeColors.true}`}
                >
                  Create Controls Automatically
                </article>
              )}
            </section>
            <p>{controlBlueprintMetadata.description}</p>
            <section className="grid grid-cols-5 gap-x-20 gap-y-10">
              {controlBlueprintMetadata.type && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Type</h4>
                  <span className="text-b1-semi">
                    {controlBlueprintMetadata.type}
                  </span>
                </article>
              )}
              {controlBlueprintMetadata.category && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Category</h4>
                  <span className="text-b1-semi">
                    {controlBlueprintMetadata.category}
                  </span>
                </article>
              )}
              {controlBlueprintMetadata.sub_category && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Subcategory
                  </h4>
                  <span className="text-b1-semi">
                    {controlBlueprintMetadata.sub_category}
                  </span>
                </article>
              )}
              {controlBlueprintMetadata.classification && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Classification
                  </h4>
                  <span className="text-b1-semi">
                    {controlBlueprintMetadata.classification}
                  </span>
                </article>
              )}
              {controlBlueprintMetadata.attestation && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Attestation
                  </h4>
                  <span className="text-b1-semi">
                    {controlBlueprintMetadata.attestation}
                  </span>
                </article>
              )}
              {owner && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Owner</h4>
                  <span className="text-b1-semi">{owner}</span>
                </article>
              )}
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default ControlBlueprintMetadata;
