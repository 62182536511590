/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import FilterLayout from "src/layouts/FilterLayout";
import { SearchGRCVerticals } from "src/services/regulation-policy/overview";
import { KeyStringVal } from "src/types/general";

const VerticalFilter = ({
  label,
  inputs,
  setInputs,
}: {
  label?: string;
  inputs: any;
  setInputs: (inputs: KeyStringVal) => void;
}) => {
  const search = SearchGRCVerticals();

  const handleSelect = (vertical: any) => {
    if (vertical === "All" && search?.data?.length > 0) {
      if (inputs.verticals.length < search.data?.length) {
        search.mutate(
          {
            region: "",
          },
          {
            onSuccess: (data) => {
              const regionCodes = data.reduce(
                (pV: string[], cV: KeyStringVal) => [
                  ...pV,
                  cV.country_code_alpha_3,
                ],
                []
              );
              setInputs({
                ...inputs,
                regions: regionCodes,
              });
            },
          }
        );
      } else setInputs({ ...inputs, regions: [] });
    } else {
      const value = `${vertical.industry}-${vertical.sub_category}`;

      if (inputs.verticals.includes(value))
        setInputs({
          ...inputs,
          verticals: inputs.verticals.filter(
            (curVertical: string) => curVertical !== value
          ),
        });
      else setInputs({ ...inputs, verticals: [...inputs.verticals, value] });
    }
  };

  useEffect(() => {
    search.mutate({
      vertical: "",
    });
  }, []);

  return (
    <FilterLayout label="Vertical">
      <ul className="flex flex-col flex-grow gap-1">
        <li className="flex items-center gap-2 w-full">
          <input
            type="checkbox"
            className="form-checkbox w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
            checked={inputs.verticals.length === search?.data?.length}
            onClick={() => handleSelect("All")}
          />
          <label htmlFor="" className="w-max">
            All
          </label>
        </li>
        {search.data?.map((vertical: KeyStringVal, verticalIdx: number) => (
          <li key={verticalIdx} className="flex items-center gap-2 w-full">
            <input
              type="checkbox"
              className="form-checkbox w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
              checked={inputs.verticals.includes(
                `${vertical.industry}-${vertical.sub_category}`
              )}
              onClick={() => handleSelect(vertical)}
            />
            <label htmlFor="" className="w-max">
              {vertical.industry} - {vertical.sub_category}
            </label>
          </li>
        ))}
      </ul>
    </FilterLayout>
  );
};

export default VerticalFilter;
