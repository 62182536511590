import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ModalLayout from "src/layouts/ModalLayout";
import { GetDocumentSummary } from "src/services/grc";

const DocumentSummary = ({ documentID }: { documentID: string }) => {
  const [showItemizedSummary, setShowItemizedSummary] =
    useState<boolean>(false);

  const { data: documentSummary } = GetDocumentSummary(documentID);

  return (
    <>
      {documentSummary && (
        <article className="grid gap-3">
          <Disclosure>
            {({ open }) => (
              <>
                <article className="grid gap-1 px-6 py-4 dark:bg-gray-800 black-shadow rounded-xl">
                  <header className="flex items-center justify-between gap-10 w-full text-subt1-semi">
                    <article className="flex items-center gap-5">
                      <h4>Summary</h4>
                      <button
                        className="px-4 py-1 w-max text-b2-reg dark:bg-gray-600 dark:hover:bg-gray-600/70 duration-100 rounded-lg"
                        onClick={() => setShowItemizedSummary(true)}
                      >
                        View itemized summary{" "}
                      </button>
                    </article>
                    <Disclosure.Button>
                      <article className="flex items-center gap-2 text-b2-reg dark:text-blue-600">
                        <FontAwesomeIcon
                          icon={open ? faChevronUp : faChevronDown}
                        />
                        <span>Show {open ? "less" : "more"}</span>
                      </article>
                    </Disclosure.Button>
                  </header>
                </article>
                <Disclosure.Panel>
                  <ReactMarkdown
                    className="px-6 py-4 -mt-7 min-w-full prose dark:prose-invert dark:bg-gray-800 rounded-xl"
                    remarkPlugins={[remarkGfm]}
                  >
                    {documentSummary.OVERALL_SUMMARY}
                  </ReactMarkdown>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <ModalLayout
            showModal={showItemizedSummary}
            onClose={() => setShowItemizedSummary(false)}
          >
            <section className="grid gap-2 overflow-auto scrollbar">
              <h4 className="text-subt1-semi">Itemized Summary</h4>
              {documentSummary.ITEMIZED_SUMMARY ? (
                <p className="grid gap-4 text-b1-reg dark:divide-y dark:divide-gray-700">
                  {documentSummary.ITEMIZED_SUMMARY.split("\n").map(
                    (phrase: string, index: number) => (
                      <span key={index} className="pt-4">
                        {phrase}
                      </span>
                    )
                  )}
                </p>
              ) : (
                <span>Not available</span>
              )}
            </section>
          </ModalLayout>
        </article>
      )}
    </>
  );
};

export default DocumentSummary;
