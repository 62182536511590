import React, { useState } from "react";
import ActivityDetail from "./ActivityDetail";
import TablePagination from "src/components/General/TablePagination";
import { pageSize } from "src/constants/general";
import AuditTrailTypesFilter from "src/components/Filter/RegulationPolicy/AuditTrailTypesFilter";
import AuditTrailUsersFilter from "src/components/Filter/RegulationPolicy/AuditTrailUsersFilter";
import { GetGRCActivity } from "src/services/regulation-policy/overview";

const Activities = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [auditTrailTypes, setAuditTrailTypes] = useState<string[]>([]);
  const [auditTrailUsers, setAuditTrailUsers] = useState<string[]>([]);

  const { data: actvities } = GetGRCActivity(
    pageNumber,
    auditTrailTypes,
    auditTrailUsers
  );

  const totalCount = actvities?.pager.total_results || 0;
  const totalPages = actvities?.pager.num_pages || 0;
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <>
      <section className="flex flex-col flex-grow gap-5">
        <section className="grid gap-3">
          <article className="flex items-center place-self-end gap-5">
            <AuditTrailTypesFilter
              inputs={auditTrailTypes}
              setInputs={setAuditTrailTypes}
            />
            <AuditTrailUsersFilter
              inputs={auditTrailUsers}
              setInputs={setAuditTrailUsers}
            />
          </article>
        </section>
        {actvities ? (
          actvities.data.length > 0 ? (
            <section className="flex flex-col flex-grow content-start gap-7 text-sm">
              {actvities.data.map((activity: any, index: number) => {
                return <ActivityDetail key={index} activity={activity} />;
              })}
            </section>
          ) : (
            <span>No activities available</span>
          )
        ) : null}
        <TablePagination
          totalPages={totalPages}
          beginning={beginning}
          end={end}
          totalCount={totalCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </section>
    </>
  );
};

export default Activities;
