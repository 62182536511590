import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import CategoryInput from "../../../../../components/Input/CategoryInput";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import CheckboxInput from "src/components/Input/CheckboxInput";
import MultiUserInput from "src/components/Input/MultiUserInput";
import { EditRisk } from "src/services/erc/risks/risks";
import RiskBlueprintFilter from "src/components/Filter/ERC/RiskBlueprintFilter";

const UpdateRisk = ({ riskID, risk }: { riskID: string; risk: any }) => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: risk.name,
    description: risk.description,
    threat: risk.threat,
    vulnerability: risk.vulnerability,
    category: risk.category,
    sub_category: risk.sub_category,
    classification: risk.classification,
    type: risk.type,
    owner_same_as_entity_owner: risk.owner_same_as_entity_owner,
    owners: risk.owners,
    reviewers: risk.reviewers,
    approvers: risk.approvers,
    treatment: risk.treatment,
    treatment_plan: risk.treatment_plan,
    deadline: risk.deadline,
    create_risks_automatically: risk.create_risks_automatically,
    inherent_risk_score: risk.inherent_risk_score,
    inherent_risk_impact_level: risk.inherent_risk_impact_level,
    inherent_risk_likelihood_level: risk.inherent_risk_likelihood_level,
    assessment: risk.assessment,
    additional_information: risk.additional_information,
    treatment_type: risk.treatment_type,
    treatment_status: risk.treatment_status,
    status: risk.status,
    risk_blueprint_id: risk.risk_blueprint_id,
  });
  const [valid, setValid] = useState<boolean>(true);

  const editRisk = EditRisk(riskID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="px-4 py-2 text-b1-reg dark:bg-gray-900 dark:hover:bg-gray-900/70 duration-100 rounded-xl"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
        }}
      >
        <FontAwesomeIcon icon={faEdit} /> Edit
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Edit Entity</h3>
          <section className="grid md:grid-cols-2 gap-5">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <RegularInput
              label="Classification"
              keyName="classification"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RiskBlueprintFilter
              label="Risk Blueprint"
              keyName="risk_blueprint_id"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Category"
              keyName="category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Subcategory"
              keyName="sub_category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Type"
              keyName="type"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CategoryInput
              label="Attestation"
              keyName="attestation"
              list={["DRAFT", "ATTEST", "REVIEW", "MONITOR", "RETIRED"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <RegularInput
              label="Threat"
              keyName="threat"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Vulnerability"
              keyName="vulnerability"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CategoryInput
              label="Treatment Type"
              keyName="treatment_type"
              list={["ACCEPT", "AVOID", "MITIGATE", "TRANSFER"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <GeneralSnapshotDatepicker
              label="Deadline"
              keyName="deadline"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CategoryInput
              label="Treatment Status"
              keyName="treatment_status"
              list={["Open", "Closed", "Resolved", "Pending"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <RegularInput
              label="Treatment Plan"
              keyName="treatment_plan"
              inputs={inputs}
              setInputs={setInputs}
            />
            <MultiUserInput
              label="Owners"
              keyName="owners"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
          </section>
          <section className="grid md:grid-cols-2 gap-5">
            <MultiUserInput
              label="Reviewers"
              keyName="reviewers"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <MultiUserInput
              label="Approvers"
              keyName="approvers"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <CheckboxInput
              label="Create Risks Automatically"
              keyName="create_risks_automatically"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CheckboxInput
              label="Owner Same as Entity Owner"
              keyName="owner_same_as_entity_owner"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CategoryInput
              label="Inherent Risk Impact Level"
              keyName="inherent_risk_impact_level"
              list={[
                "NEGLIGIBLE",
                "MINOR",
                "MODERATE",
                "SIGNIFICANT",
                "SEVERE",
              ]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <CategoryInput
              label="Inherent Risk Likelihood Level"
              keyName="inherent_risk_likelihood_level"
              list={[
                "VERY_UNLIKELY",
                "UNLIKELY",
                "POSSIBLE",
                "LIKELY",
                "VERY_LIKELY",
              ]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <CategoryInput
              label="Status"
              keyName="status"
              list={["DRAFT", "INACTIVE", "ACTIVE", "ARCHIVED"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
          </section>
          <RegularInput
            label="Description"
            keyName="description"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                editRisk.mutate({
                  info: inputs,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UpdateRisk;
