import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { userColors } from "src/constants/general";
import { GetAllUsers } from "src/services/settings/users";
import { KeyStringVal } from "src/types/general";
import { User } from "src/types/settings";
import { getCustomerID } from "src/utils/general";

const MultiUserInput = ({
  label,
  keyName,
  inputs,
  setInputs,
  allowUnselect,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: (inputs: any) => void;
  allowUnselect?: boolean;
}) => {
  const customerID = getCustomerID();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const { data: allUsers } = GetAllUsers(customerID, false);

  const filteredUsers = allUsers?.filter((user: KeyStringVal) =>
    user.user_email
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(query.toLowerCase().replace(/\s+/g, ""))
  );

  return (
    <article className="grid content-start text-b2-reg">
      <h4 className="justify-self-start">{label}</h4>
      <article
        onMouseLeave={() => setShowDropdown(false)}
        onClick={() => setShowDropdown(true)}
        className="relative px-4 py-2 w-full h-9 dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 focus:outline-none rounded"
      >
        <article className="flex items-center justify-between gap-2">
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder="Select User"
            value={query ? query : `${inputs[keyName].length} selected`}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
            }}
            className="border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon
              className="w-5 h-5 dark:text-gray-500"
              aria-hidden="true"
            />
          </span>
        </article>
        {showDropdown && (
          <article className="absolute top-9 left-0 grid gap-1 p-1 content-start w-full max-h-36 dark:bg-gray-900 focus:outline-none shadow dark:shadow-black overflow-auto scrollbar rounded-b-sm z-10">
            {filteredUsers?.map((user: User) => {
              const selected = inputs[keyName].includes(user.user_id);
              return (
                <button
                  key={user.user_email}
                  className={`relative flex items-start gap-2 px-4 py-1 break-words dark:hover:bg-gray-800 duration-100 ${
                    selected ? "dark:bg-gray-800" : ""
                  } rounded-lg`}
                  onClick={() => {
                    setQuery("");
                    if (allowUnselect && selected)
                      setInputs({
                        ...inputs,
                        [keyName]: inputs[keyName].filter(
                          (userID) => user.user_id !== userID
                        ),
                      });
                    else
                      setInputs({
                        ...inputs,
                        [keyName]: [...inputs[keyName], user.user_id],
                      });
                  }}
                >
                  {selected && (
                    <FontAwesomeIcon icon={faCheck} className="text-no" />
                  )}
                  <span
                    className={`grid content-center w-5 h-5 capitalize text-center text-[0.65rem] dark:text-white font-medium bg-gradient-to-b ${
                      userColors[user.user_email[0].toLowerCase()]
                    } shadow-sm dark:shadow-checkbox rounded-full`}
                  >
                    {user.user_email[0]}
                  </span>
                  <p className="grid text-left">
                    {user.user_email}{" "}
                    <span className="text-xs dark:text-checkbox">
                      {user.user_name}
                    </span>
                  </p>
                </button>
              );
            })}
          </article>
        )}
      </article>
      <ul className="flex flex-wrap items-center gap-2 mt-1">
        {inputs[keyName].map((userID: string) => {
          const email =
            allUsers?.find((user: KeyStringVal) => user.user_id === userID)
              ?.user_email || "";
          return (
            <li key={userID} className="px-2 py-1 dark:bg-gray-600 rounded-md">
              {email}
            </li>
          );
        })}
      </ul>
    </article>
  );
};

export default MultiUserInput;
