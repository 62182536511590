/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { showVariants } from "src/constants/general";
import { motion } from "framer-motion";
import PageLayout from "src/layouts/PageLayout";
import { useGRCStore } from "src/stores/grc";
import { ercTabs } from "../../constants/grc";
import Entities from "./Entities/Entities";
import Controls from "./Controls/Controls";
import Risks from "./Risks/Risks";

const ERC = () => {
  const { GRCCategory, setGRCCategory, setGRCQuery } = useGRCStore();

  const [selectedTab, setSelectedTab] = useState<string>("entities");

  useEffect(() => {
    sessionStorage.page = "Entities, Risks, & Controls";
    if (ercTabs.includes(sessionStorage.GRCCategory))
      setGRCCategory(sessionStorage.GRCCategory);
    else setGRCCategory("entities");
    setGRCQuery("");
  }, []);

  return (
    <PageLayout>
      <motion.main
        variants={showVariants}
        initial="hidden"
        animate="visible"
        className="flex flex-col flex-grow gap-5 p-4 w-full h-full text-base overflow-auto scrollbar"
      >
        <section className="flex flex-wrap items-center justify-between gap-10 text-b1-reg">
          <nav className="flex flex-wrap items-center gap-1 p-1 dark:bg-gray-900 rounded-lg">
            {ercTabs.map((tab) => {
              return (
                <button
                  key={tab}
                  className={`px-4 py-1 text-center capitalize ${
                    GRCCategory === tab
                      ? "dark:text-white dark:bg-gray-800"
                      : "dark:text-gray-500 dark:hover:bg-gray-500/30 duration-100"
                  } rounded-lg`}
                  onClick={() => {
                    sessionStorage.GRCCategory = tab;
                    setGRCCategory(tab);
                    setSelectedTab(tab);
                  }}
                >
                  {tab}
                </button>
              );
            })}
          </nav>
        </section>

        {GRCCategory === "entities" ? (
          <Entities selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        ) : GRCCategory === "risks" ? (
          <Risks selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        ) : GRCCategory === "controls" ? (
          <Controls selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        ) : null}
      </motion.main>
    </PageLayout>
  );
};

export default ERC;
