/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { GetControlEvidence } from "src/services/audit-management";
import EvidenceDetail from "./EvidenceDetail";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import {
  GetEvidenceReport,
  UploadEvidenceReport,
} from "../../../../../services/audit-management";
import { motion } from "framer-motion";
import MultiUserInput from "../../../../../components/Input/MultiUserInput";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import RadioInput from "../../../../../components/Input/RadioInput";

const EditEvidence = ({
  auditID,
  controlID,
  showModal,
  setShowModal,
  inputs,
  setInputs,
}: {
  auditID: string;
  controlID: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  inputs: any;
  setInputs: any;
}) => {
  const [saved, setSaved] = useState<boolean>(false);

  const { data: evidenceList } = GetControlEvidence(auditID, controlID);
  const { data: evidenceReport, status: evidenceReportStatus } =
    GetEvidenceReport(auditID, controlID);
  const uploadEvidenceReport = UploadEvidenceReport(auditID, controlID);

  const handleSaveEvidenceReport = () => {
    uploadEvidenceReport.mutate(
      {
        report: {
          exclude: inputs.exclude,
          ...(inputs.testing_procedure && {
            testing_procedure: inputs.testing_procedure,
          }),
          ...(inputs.instructions && {
            instructions: inputs.instructions,
          }),
          ...(inputs.exclude_notes && {
            exclude_notes: inputs.exclude_notes,
          }),
          users: inputs.users.length > 0 ? inputs.users : null,
          due_date: Date.now() * 1000,
        },
      },
      {
        onSuccess: () => {
          setSaved(true);
          setTimeout(() => {
            setSaved(false);
            handleOnClose();
          }, 5000);
        },
      }
    );
  };
  const handleOnClose = () => setShowModal(false);

  useEffect(() => {
    if (evidenceReportStatus === "success") {
      if (evidenceReport)
        setInputs({
          exclude: evidenceReport.exclude,
          testing_procedure: evidenceReport.testing_procedure || "",
          instructions: evidenceReport.instructions || "",
          exclude_notes: evidenceReport.exclude_notes || "",
          users: evidenceReport.users || [],
          due_date: evidenceReport.due_date || Date.now() * 1000,
        });
      else
        setInputs({
          exclude: false,
          testing_procedure: "inquiry",
          instructions: "",
          exclude_notes: "",
          users: [],
          due_date: Date.now() * 1000,
        });
    }
  }, [evidenceReport]);

  return (
    <>
      <button
        className="flex items-center gap-2 px-4 py-2 w-max cursor-pointer text-b2-reg dark:text-white dark:disabled:text-gray-300 dark:disabled:bg-blue-600/50 dark:bg-blue-600 dark:hover:bg-blue-600/70 duration-100 rounded-xl"
        onClick={() => setShowModal(true)}
      >
        <h4>Edit Evidence</h4>
      </button>
      <ModalLayout showModal={showModal} onClose={handleOnClose}>
        <section className="grid gap-5 text-b2-reg">
          <h3 className="flex items-center gap-2 text-t1-semi">Evidence</h3>
          {!inputs.exclude ? (
            <article className="flex flex-col flex-grow gap-5">
              <MultiUserInput
                label="Assigned to"
                keyName="users"
                inputs={inputs}
                setInputs={setInputs}
                allowUnselect
              />
              <RadioInput
                label="Testing Procedure"
                keyName="testing_procedure"
                list={["inquiry", "observation", "inspection"]}
                inputs={inputs}
                setInputs={setInputs}
              />
              <RegularInput
                label="Note"
                keyName="instructions"
                inputs={inputs}
                setInputs={setInputs}
                textarea
              />
              <GeneralSnapshotDatepicker
                label="Due Date"
                keyName="due_date"
                inputs={inputs}
                setInputs={setInputs}
              />
            </article>
          ) : (
            <RegularInput
              label="Note"
              keyName="exclude_notes"
              inputs={inputs}
              setInputs={setInputs}
              textarea
            />
          )}
          <article className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={inputs.exclude}
              onClick={() =>
                setInputs({
                  exclude: !inputs.exclude,
                  testing_procedure: "",
                  instructions: "",
                  exclude_notes: "",
                  users: [],
                  due_date: Date.now() * 1000,
                })
              }
              className="form-checkbox w-4 h-4 border-0 dark:focus:ring-0 dark:text-blue-500 dark:focus:border-blue-500 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-500 focus:ring-opacity-50"
            />
            <label className="w-max">Exclude</label>
          </article>

          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button className="blue-button" onClick={handleSaveEvidenceReport}>
              Save
            </button>
          </article>
          {saved && (
            <motion.article
              initial={{ y: 3, opacity: 0 }}
              animate={{
                y: 0,
                opacity: 1,
                transition: { duration: 0.3 },
              }}
              className="flex items-center gap-2 text-xs"
            >
              <FontAwesomeIcon
                icon={faCheck}
                className="w-3 h-3 dark:text-contact"
              />
              Successfully saved
            </motion.article>
          )}
          {!inputs.exclude && (
            <article className="flex flex-col flex-grow gap-5 text-b2-reg">
              {evidenceList?.length > 0 && (
                <ul className="flex flex-wrap gap-2">
                  {evidenceList.map((evidence: any) => {
                    return (
                      <EvidenceDetail
                        key={evidence.evidence_id}
                        auditID={auditID}
                        controlID={controlID}
                        evidence={evidence}
                      />
                    );
                  })}
                </ul>
              )}
            </article>
          )}
        </section>
      </ModalLayout>
    </>
  );
};

export default EditEvidence;
