/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FilterLayout from "src/layouts/FilterLayout";
import { GetAuditTrailTypes } from "src/services/regulation-policy/overview";
import { checkIsAdminOrSuperAdmin } from "src/utils/general";

const AuditTrailTypesFilter = ({
  inputs,
  setInputs,
}: {
  inputs: string[];
  setInputs: (inputs: string[]) => void;
}) => {
  const isAdmin = checkIsAdminOrSuperAdmin();

  const { data: auditTrailTypes } = GetAuditTrailTypes();

  const handleSelect = (value: string) => {
    if (value === "All" && auditTrailTypes?.length > 0) {
      if (inputs.length < auditTrailTypes.length) setInputs(auditTrailTypes);
      else setInputs([]);
    } else if (inputs.includes(value))
      setInputs(inputs.filter((curItem: string) => curItem !== value));
    else setInputs([...inputs, value]);
  };

  return (
    <FilterLayout label="Activity Types" showOnLeft>
      <ul className="flex flex-col flex-grow gap-1">
        <li className="flex items-center gap-2 w-full">
          <input
            type="checkbox"
            className="form-checkbox w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
            checked={inputs.length === auditTrailTypes?.length}
            onClick={() => handleSelect("All")}
          />
          <label htmlFor="" className="w-max">
            All
          </label>
        </li>
        {auditTrailTypes?.map((option: string) => {
          if (
            ["USER_SIGNED_IN", "USER_SIGNED_OUT"].includes(option) &&
            !isAdmin
          )
            return null;
          return (
            <li key={option} className="flex items-center gap-2 w-full">
              <input
                type="checkbox"
                className="form-checkbox w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                checked={inputs.includes(option)}
                onClick={() => handleSelect(option)}
              />
              <label htmlFor="" className="w-max">
                {option.replaceAll("_", " ")}
              </label>
            </li>
          );
        })}
      </ul>
    </FilterLayout>
  );
};

export default AuditTrailTypesFilter;
