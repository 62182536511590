import React from "react";
import FilterLayout from "src/layouts/FilterLayout";

const Filters = ({
  filters,
  setFilters,
}: {
  filters: any;
  setFilters: any;
}) => {
  return (
    <FilterLayout label="Quick Filters">
      <ul className="flex flex-col flex-grow gap-1">
        {["All", "Relevant Sections", "Assigned to Me"].map((filter) => {
          return (
            <li key={filter} className="flex items-center gap-2 w-full">
              <input
                type="radio"
                className="form-radio w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                checked={filters.filter === filter}
                onChange={() => setFilters({ ...filters, filter })}
              />
              <label htmlFor="" className="w-max">
                {filter}
              </label>
            </li>
          );
        })}
      </ul>
      <ul className="flex flex-col flex-grow gap-1">
        {["hide_excluded", "analysis_generated", "has_evidence"].map(
          (filter) => {
            return (
              <li key={filter} className="flex items-center gap-2 w-full">
                <input
                  type="checkbox"
                  className="form-checkbox w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                  checked={filters[filter]}
                  onChange={() =>
                    setFilters({
                      ...filters,
                      [filter]: !filters[filter],
                    })
                  }
                />
                <label htmlFor="" className="capitalize w-max">
                  {filter.replaceAll("_", " ")}
                </label>
              </li>
            );
          }
        )}
      </ul>
    </FilterLayout>
  );
};

export default Filters;
