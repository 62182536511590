import React from "react";
import { GetVendorResponseTags } from "src/services/vendor-webform";

const AllTags = ({
  selectedTags,
  setSelectedTags,
}: {
  selectedTags: string[];
  setSelectedTags: (selectedTags: string[]) => void;
}) => {
  const { data: allTags } = GetVendorResponseTags();

  return (
    <ul className="flex flex-wrap items-center gap-4 p-6 text-b1-reg dark:bg-gray-800 rounded-2xl">
      {allTags?.map((tag: string) => {
        return (
          <li
            key={tag}
            className={`flex items-center gap-3 px-4 py-1 cursor-pointer dark:text-white ${
              selectedTags.includes(tag)
                ? "dark:bg-blue-500"
                : "dark:hover:bg-gray-700/30 duration-100"
            } dark:bg-gray-700 rounded`}
            onClick={() => {
              if (selectedTags.includes(tag))
                setSelectedTags(selectedTags.filter((tagID) => tag !== tagID));
              else setSelectedTags([...selectedTags, tag]);
            }}
          >
            <p>{tag}</p>
          </li>
        );
      })}
    </ul>
  );
};

export default AllTags;
