import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faMinus,
  faPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import RadioInput from "../../../../../components/Input/RadioInput";
import UserInput from "../../../../../components/Input/UserInput";
import ToastLayout from "../../../../../layouts/ToastLayout";
import { AddRiskQuestionnaire } from "src/services/erc/risks/assessments/questionnaire";
import {
  GetRiskQuestions,
  AddRiskQuestion,
} from "src/services/erc/risks/assessments/questions";
import { Disclosure } from "@headlessui/react";

const NewRiskQuestionnaire = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    owner_id: "",
    questions_by_category_list: [],
  });
  const [newQuestion, setNewQuestion] = useState<any>({
    question: "",
    question_type: "",
    guideline_or_rubric: "",
  });
  const [addNewQuestion, setAddNewQuestion] = useState<boolean>(false);

  const { data: riskQuestions } = GetRiskQuestions();
  const addRiskQuestion = AddRiskQuestion();
  const addRiskQuestionnaire = AddRiskQuestionnaire();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addRiskQuestionnaire.reset();

  return (
    <>
      <button
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            owner_id: "",
            questions_by_category_list: [],
          });
          setNewQuestion({
            question: "",
            question_type: "",
            guideline_or_rubric: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Questionnaire</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 w-full h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add Questionnaire
          </h3>
          <section className="grid gap-5 w-full">
            <section className="grid content-start md:grid-cols-2 gap-5">
              <RegularInput
                label="Name"
                keyName="name"
                inputs={inputs}
                setInputs={setInputs}
                required
              />
              <UserInput
                label="Owner"
                keyName="owner_id"
                inputs={inputs}
                setInputs={setInputs}
                vertical
                required
              />
            </section>
            <section className="grid gap-3">
              {inputs.questions_by_category_list.map(
                (category: any, idx: number) => {
                  return (
                    <article key={idx} className="grid gap-3">
                      <Disclosure defaultOpen>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex items-center gap-2">
                              <p>{open ? "Hide" : "Show"} Category</p>
                              <FontAwesomeIcon
                                icon={open ? faChevronDown : faChevronRight}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="grid gap-5 p-5 dark:bg-gray-700 rounded-xl">
                              <article className="grid grid-cols-2 gap-5">
                                <article className="grid gap-2">
                                  <header className="flex items-center gap-1 justify-self-start">
                                    <h4>Category</h4>
                                    <span className="text-h5 text-red-500">
                                      *
                                    </span>
                                  </header>
                                  <input
                                    spellCheck="false"
                                    autoComplete="off"
                                    value={category.name}
                                    placeholder="Input text"
                                    onChange={(e) => {
                                      setInputs({
                                        ...inputs,
                                        questions_by_category_list:
                                          inputs.questions_by_category_list.map(
                                            (curC, cIdx: number) => {
                                              if (cIdx === idx)
                                                return {
                                                  ...curC,
                                                  name: e.target.value,
                                                };
                                              else return curC;
                                            }
                                          ),
                                      });
                                    }}
                                    type="input"
                                    className="px-4 py-2 w-full h-9 focus:outline-none text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 rounded"
                                  />
                                </article>
                                <article className="grid gap-2">
                                  <h4 className="text-b2-reg">Weight</h4>
                                  <input
                                    spellCheck="false"
                                    autoComplete="off"
                                    value={category.weight}
                                    placeholder="1"
                                    onChange={(e) => {
                                      setInputs({
                                        ...inputs,
                                        questions_by_category_list:
                                          inputs.questions_by_category_list.map(
                                            (curC, cIdx: number) => {
                                              if (cIdx === idx)
                                                return {
                                                  ...curC,
                                                  weight: Number(
                                                    e.target.value
                                                  ),
                                                };
                                              else return curC;
                                            }
                                          ),
                                      });
                                    }}
                                    type="input"
                                    className="px-4 py-2 w-full h-9 focus:outline-none text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 rounded"
                                  />
                                </article>
                              </article>
                              <article className="grid gap-2">
                                <h4 className="text-b2-reg">Description</h4>
                                <input
                                  spellCheck="false"
                                  autoComplete="off"
                                  value={category.description}
                                  onChange={(e) => {
                                    setInputs({
                                      ...inputs,
                                      questions_by_category_list:
                                        inputs.questions_by_category_list.map(
                                          (curC, cIdx: number) => {
                                            if (cIdx === idx)
                                              return {
                                                ...curC,
                                                description: e.target.value,
                                              };
                                            else return curC;
                                          }
                                        ),
                                    });
                                  }}
                                  type="input"
                                  className="px-4 py-2 w-full h-9 focus:outline-none text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 rounded"
                                />
                              </article>

                              <section className="flex flex-col flex-grow gap-5">
                                {addNewQuestion ? (
                                  <section className="flex items-center place-self-end gap-5">
                                    <button
                                      className="red-button"
                                      onClick={() => setAddNewQuestion(false)}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      disabled={Object.values(
                                        newQuestion
                                      ).includes("")}
                                      className="blue-button"
                                      onClick={() => {
                                        addRiskQuestion.mutate({
                                          info: {
                                            ...newQuestion,
                                            question_type:
                                              newQuestion.question_type ===
                                              "Yes/No"
                                                ? "YES_NO"
                                                : "OPEN_ENDED",
                                          },
                                        });
                                        setAddNewQuestion(false);
                                        setNewQuestion({
                                          question: "",
                                          question_type: "",
                                          point_scoring_response: "",
                                        });
                                      }}
                                    >
                                      Save Question
                                    </button>
                                  </section>
                                ) : (
                                  <button
                                    className="place-self-end blue-button"
                                    onClick={() => setAddNewQuestion(true)}
                                  >
                                    Add New Question
                                  </button>
                                )}
                                {addNewQuestion && (
                                  <section className="grid content-start md:grid-cols-2 gap-5 p-4 dark:bg-gray-700 rounded-xl">
                                    <RadioInput
                                      label="Question Type"
                                      keyName="question_type"
                                      list={["Yes/No", "Open Ended"]}
                                      inputs={newQuestion}
                                      setInputs={setNewQuestion}
                                      required
                                    />
                                    <RegularInput
                                      label="Question"
                                      keyName="question"
                                      inputs={newQuestion}
                                      setInputs={setNewQuestion}
                                      required
                                    />
                                    <RegularInput
                                      label="Guideline or Rubric"
                                      keyName="guideline_or_rubric"
                                      inputs={newQuestion}
                                      setInputs={setNewQuestion}
                                      required
                                    />
                                  </section>
                                )}
                                <span>Select questions</span>
                                <ul className="grid gap-2">
                                  {riskQuestions?.data?.map(
                                    (row: any, index: number) => {
                                      const selected = category.questions?.some(
                                        (question) =>
                                          question.question_id ===
                                          row.question_id
                                      );
                                      const weight =
                                        category.questions?.find(
                                          (question) =>
                                            question.question_id ===
                                            row.question_id
                                        )?.weight || "";
                                      return (
                                        <li
                                          key={index}
                                          data-test="table-row"
                                          className="flex items-center gap-2"
                                        >
                                          <button
                                            className="blue-button"
                                            onClick={() => {
                                              if (selected)
                                                setInputs({
                                                  ...inputs,
                                                  questions_by_category_list:
                                                    inputs.questions_by_category_list.map(
                                                      (curC, cIdx: number) => {
                                                        if (cIdx === idx)
                                                          return {
                                                            ...curC,
                                                            questions:
                                                              curC.questions?.filter(
                                                                (curQ) =>
                                                                  curQ.question_id !==
                                                                  row.question_id
                                                              ),
                                                          };
                                                        else return curC;
                                                      }
                                                    ),
                                                });
                                              else
                                                setInputs({
                                                  ...inputs,
                                                  questions_by_category_list:
                                                    inputs.questions_by_category_list.map(
                                                      (curC, cIdx: number) => {
                                                        if (cIdx === idx) {
                                                          return {
                                                            ...curC,
                                                            questions: [
                                                              ...(curC.questions ||
                                                                []),
                                                              {
                                                                question_id:
                                                                  row.question_id,
                                                                weight: 1,
                                                              },
                                                            ],
                                                          };
                                                        } else return curC;
                                                      }
                                                    ),
                                                });
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={selected ? faMinus : faPlus}
                                            />
                                          </button>
                                          <p>Q: {row.question}</p>
                                          <input
                                            spellCheck="false"
                                            autoComplete="off"
                                            value={weight}
                                            placeholder="Weight"
                                            onChange={(e) => {
                                              setInputs({
                                                ...inputs,
                                                questions_by_category_list:
                                                  inputs.questions_by_category_list.map(
                                                    (curC, cIdx: number) => {
                                                      if (cIdx === idx)
                                                        return {
                                                          ...curC,
                                                          questions:
                                                            curC.questions?.map(
                                                              (question) => {
                                                                if (
                                                                  question.question_id ===
                                                                  row.question_id
                                                                )
                                                                  return {
                                                                    ...question,
                                                                    weight:
                                                                      Number(
                                                                        e.target
                                                                          .value
                                                                      ),
                                                                  };
                                                                else
                                                                  return question;
                                                              }
                                                            ),
                                                        };
                                                      else return curC;
                                                    }
                                                  ),
                                              });
                                            }}
                                            type="input"
                                            className="px-4 py-2 w-20 h-9 focus:outline-none text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 rounded"
                                          />
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </section>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </article>
                  );
                }
              )}
              <button
                className="place-self-end blue-button"
                onClick={() =>
                  setInputs({
                    ...inputs,
                    questions_by_category_list: [
                      ...inputs.questions_by_category_list,
                      { name: "", description: "", weight: 1 },
                    ],
                  })
                }
              >
                <FontAwesomeIcon icon={faPlus} />
                Add Category
              </button>
            </section>
          </section>
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                inputs.name === "" ||
                inputs.owner_id === "" ||
                inputs.questions_by_category_list.length === 0
              }
              className="blue-button"
              onClick={() => {
                addRiskQuestionnaire.mutate({
                  info: inputs,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addRiskQuestionnaire.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New risk questionnaire has been created"
      />
    </>
  );
};

export default NewRiskQuestionnaire;
