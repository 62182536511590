import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import CategoryInput from "../../../../../components/Input/CategoryInput";
import { EditEntity } from "../../../../../services/erc/entities/entities";

const UpdateEntity = ({
  entityID,
  entity,
}: {
  entityID: string;
  entity: any;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: entity.name,
    status: entity.status,
    external_reference_id: entity.external_reference_id,
  });
  const [valid, setValid] = useState<boolean>(true);

  const editEntity = EditEntity(entityID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="px-4 py-2 text-b1-reg dark:bg-gray-900 dark:hover:bg-gray-900/70 duration-100 rounded-xl"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
        }}
      >
        <FontAwesomeIcon icon={faEdit} /> Edit
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Edit Entity</h3>
          <section className="grid md:grid-cols-2 gap-5">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <CategoryInput
              label="Status"
              keyName="status"
              list={["DRAFT", "INACTIVE", "ACTIVE", "ARCHIVED"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <RegularInput
              label="External Reference Id"
              keyName="external_reference_id"
              inputs={inputs}
              setInputs={setInputs}
            />
          </section>
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                editEntity.mutate({
                  info: inputs,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UpdateEntity;
