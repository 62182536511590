/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import FilterLayout from "src/layouts/FilterLayout";
import { SearchGRCRegion } from "src/services/regulation-policy/overview";
import { KeyStringVal } from "src/types/general";

const RegionFilter = ({
  label,
  inputs,
  setInputs,
}: {
  label?: string;
  inputs: any;
  setInputs: (inputs: KeyStringVal) => void;
}) => {
  const search = SearchGRCRegion();

  const handleSelect = (region: string) => {
    if (region === "All" && search?.data?.length > 0) {
      if (inputs.regions.length < search.data?.length) {
        search.mutate(
          {
            region: "",
          },
          {
            onSuccess: (data) => {
              const regionCodes = data.reduce(
                (pV: string[], cV: KeyStringVal) => [
                  ...pV,
                  cV.country_code_alpha_3,
                ],
                []
              );
              setInputs({
                ...inputs,
                regions: regionCodes,
              });
            },
          }
        );
      } else setInputs({ ...inputs, regions: [] });
    } else if (inputs.regions.includes(region))
      setInputs({
        ...inputs,
        regions: inputs.regions.filter(
          (curRegion: string) => curRegion !== region
        ),
      });
    else setInputs({ ...inputs, regions: [...inputs.regions, region] });
  };

  useEffect(() => {
    search.mutate({
      region: "",
    });
  }, []);

  return (
    <FilterLayout label="Region">
      <ul className="flex flex-col flex-grow gap-1">
        <li className="flex items-center gap-2 w-full">
          <input
            type="checkbox"
            className="form-checkbox w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
            checked={inputs.regions.length === search?.data?.length}
            onClick={() => handleSelect("All")}
          />
          <label htmlFor="" className="w-max">
            All
          </label>
        </li>
        {search.data?.map((region: KeyStringVal, regionIdx: number) => (
          <li key={regionIdx} className="flex items-center gap-2 w-full">
            <input
              type="checkbox"
              className="form-checkbox w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
              checked={inputs.regions.includes(region.country_code_alpha_3)}
              onClick={() => handleSelect(region.country_code_alpha_3)}
            />
            <label htmlFor="" className="w-max">
              {region.country_code_alpha_3} - {region.country_name}
            </label>
          </li>
        ))}
      </ul>
    </FilterLayout>
  );
};

export default RegionFilter;
