import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import {
  EditVendor,
  GetVendorFieldValues,
} from "src/services/third-party-risk/vendors/vendors";
import { EditVendorLogo } from "../../../../services/third-party-risk/vendors/vendors";
import { queryClient } from "src/App";
import LogoInput from "src/components/Input/LogoInput";
import CategoryInput from "../../../../components/Input/CategoryInput";
import { validateEmail } from "../../../../utils/general";
import CheckboxInput from "src/components/Input/CheckboxInput";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import MultiCategoryInput from "src/components/Input/MultiCategoryInput";
import UserInput from "src/components/Input/UserInput";
import { Disclosure } from "@headlessui/react";

const UpdateVendor = ({
  vendorID,
  vendor,
}: {
  vendorID: string;
  vendor: any;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: vendor.name || "",
    last_updated: vendor.last_updated,
    inherent_risk: vendor.inherent_risk || "",
    address: vendor.address || "",
    contact_name: vendor.contact_name || "",
    contact_email: vendor.contact_email || "",
    contact_phone: vendor.contact_phone || "",
    number_of_employees: vendor.number_of_employees,
    internal_email_list: vendor.internal_email_list || "",
    business_user_name: vendor.business_user_name,
    department_head: vendor.department_head,
    location: vendor.location,
    department: vendor.department,
    vendor_type: vendor.vendor_type,
    vendor_manager: vendor.vendor_manager,
    vendor_tier: vendor.vendor_tier,
    rank_tier: vendor.rank_tier,
    website: vendor.website,
    duns_number: vendor.duns_number,
    status: vendor.status,
    logo: vendor.logo,
    is_publicly_traded: vendor.is_publicly_traded,
    notes: vendor.notes,
    workflow: vendor.workflow,
    stage: vendor.stage,
    created_by: vendor.created_by,
    dj_nth_party_risks: vendor.dj_nth_party_risks,
    help_ticket: vendor.help_ticket,
    sso_enabled: vendor.sso_enabled,
    is_PHI: vendor.is_PHI,
    is_PII: vendor.is_PII,
    authentication_protocol: vendor.authentication_protocol,
    data_host_location: vendor.data_host_location,
    primary_app_service: vendor.primary_app_service,
    impacted_systems: vendor.impacted_systems,
    review_checklist: vendor.review_checklist,
    description: vendor.description,
    additional_notes: vendor.additional_notes,
    sponsor: vendor.sponsor,
    data_classification: vendor.data_classification,
    baa_executed: vendor.baa_executed,
    creation_date: vendor.creation_date,
    assessed_for_ai_functionality: vendor.assessed_for_ai_functionality,
    last_updated_by: vendor.last_updated_by,
    ref_id: vendor.ref_id,
    last_review_date: vendor.last_review_date,
    next_review_date: vendor.next_review_date,
    reassessment_frequency: vendor.reassessment_frequency,
    target_primary_application_service:
      vendor.target_primary_application_service,
    additional_information: vendor.additional_information,
    analyst_comments: vendor.analyst_comments,
    criticality: vendor.criticality,
    historical_data: vendor.historical_data,
    external_document_url: vendor.external_document_url,
  });
  const [valid, setValid] = useState<boolean>(true);

  const editVendor = EditVendor(vendorID);
  const editVendorLogo = EditVendorLogo();
  const { data: ssoEnabled } = GetVendorFieldValues("sso_enabled");
  const { data: dataClassification } = GetVendorFieldValues(
    "data_classification"
  );
  const { data: reassessmentFrequency } = GetVendorFieldValues(
    "reassessment_frequency"
  );
  const { data: authenticationProtocol } = GetVendorFieldValues(
    "authentication_protocol"
  );
  const { data: reviewChecklist } = GetVendorFieldValues("review_checklist");
  const { data: baaExecuted } = GetVendorFieldValues("baa_executed");

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="px-4 py-2 text-b1-reg dark:bg-gray-900 dark:hover:bg-gray-900/70 duration-100 rounded-xl"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
        }}
      >
        <FontAwesomeIcon icon={faEdit} /> Edit
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Edit Vendor</h3>
          <section className="grid gap-10 w-full h-full">
            <LogoInput
              label="Logo"
              keyName="logo"
              types={["jpeg", "png", "svg"]}
              inputs={inputs}
              setInputs={setInputs}
            />
            <Disclosure defaultOpen>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">
                      Primary Vendor Information
                    </h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-2 content-start gap-5 w-full">
                      <RegularInput
                        label="Vendor"
                        keyName="name"
                        inputs={inputs}
                        setInputs={setInputs}
                        valid={valid}
                        setValid={setValid}
                        required
                      />
                      <RegularInput
                        label="Vendor Type"
                        keyName="vendor_type"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CategoryInput
                        label="Assessed for AI Functionality"
                        keyName="assessed_for_ai_functionality"
                        list={["N/A", "No", "Yes"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <RegularInput
                        label="Website"
                        keyName="website"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Address"
                        keyName="address"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Number of Employees"
                        keyName="number_of_employees"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Ref-ID"
                        keyName="ref_id"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Description"
                        keyName="description"
                        inputs={inputs}
                        setInputs={setInputs}
                        textarea
                      />
                      <CategoryInput
                        label="Status"
                        keyName="status"
                        list={["active", "inactive"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CategoryInput
                        label="Inherent Risk"
                        keyName="inherent_risk"
                        list={["critical", "high", "medium", "low"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CategoryInput
                        label="Rank Tier"
                        keyName="rank_tier"
                        list={["critical", "high", "medium", "low"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CheckboxInput
                        label="Publicly traded"
                        keyName="is_publicly_traded"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">Risk Management</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-2 gap-5">
                      <RegularInput
                        label="Application Owner Email"
                        keyName="primary_app_service"
                        inputs={inputs}
                        setInputs={setInputs}
                        singleEmail
                      />
                      <UserInput
                        label="Vendor Manager Email"
                        keyName="vendor_manager"
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <RegularInput
                        label="Help Ticket"
                        keyName="help_ticket"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Vendor Sponsor"
                        keyName="sponsor"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CategoryInput
                        label="Criticality"
                        keyName="criticality"
                        list={["Partner", "Critical", "High", "Medium", "Low"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CategoryInput
                        label="Data Classification"
                        keyName="data_classification"
                        list={dataClassification?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <GeneralSnapshotDatepicker
                        label="Last Review Date"
                        keyName="last_review_date"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <GeneralSnapshotDatepicker
                        label="Next Review Date"
                        keyName="next_review_date"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CategoryInput
                        label="Reassessment Frequency"
                        keyName="reassessment_frequency"
                        list={reassessmentFrequency?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <RegularInput
                        label="Notes"
                        keyName="notes"
                        inputs={inputs}
                        setInputs={setInputs}
                        textarea
                      />
                      <RegularInput
                        label="Additional Notes"
                        keyName="additional_notes"
                        inputs={inputs}
                        setInputs={setInputs}
                        textarea
                      />
                      <RegularInput
                        label="Analyst Comments"
                        keyName="analyst_comments"
                        inputs={inputs}
                        setInputs={setInputs}
                        textarea
                      />
                      <MultiCategoryInput
                        label="Vendor Review Checklist"
                        keyName="review_checklist"
                        list={reviewChecklist?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <RegularInput
                        label="Additional Information"
                        keyName="additional_information"
                        inputs={inputs}
                        setInputs={setInputs}
                        textarea
                      />
                      <RegularInput
                        label="Impacted Systems"
                        keyName="impacted_systems"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CategoryInput
                        label="SSO Enabled"
                        keyName="sso_enabled"
                        list={ssoEnabled?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CategoryInput
                        label="Vendor Tier"
                        keyName="vendor_tier"
                        list={["critical", "high", "medium", "low"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CategoryInput
                        label="Business Associate Agreement Executed"
                        keyName="baa_executed"
                        list={baaExecuted?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">
                      Vendor Contact Information
                    </h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-2 gap-5">
                      <RegularInput
                        label="Contact Name"
                        keyName="contact_name"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Contact Email"
                        keyName="contact_email"
                        inputs={inputs}
                        setInputs={setInputs}
                        singleEmail
                      />
                      <RegularInput
                        label="Contact Phone"
                        keyName="contact_phone"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Location"
                        keyName="location"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Data Host Location"
                        keyName="data_host_location"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">Scoping Details</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-2 gap-5">
                      <RegularInput
                        label="Target Primary Application Service"
                        keyName="target_primary_application_service"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CategoryInput
                        label="Authentication Protocol"
                        keyName="authentication_protocol"
                        list={authenticationProtocol?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CheckboxInput
                        label="PHI"
                        keyName="is_PHI"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CheckboxInput
                        label="PII"
                        keyName="is_PII"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">Others</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-2 gap-5">
                      <RegularInput
                        label="Workflow"
                        keyName="workflow"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Dow Jones Nth Party Risks"
                        keyName="dj_n_party_risks"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Internal Email CC List"
                        keyName="internal_email_list"
                        inputs={inputs}
                        setInputs={setInputs}
                        multipleEmail
                      />
                      <RegularInput
                        label="Name of Business User"
                        keyName="business_user_name"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Head of Department"
                        keyName="department_head"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Department"
                        keyName="department"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Stage"
                        keyName="stage"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Historical Data"
                        keyName="historical_data"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="External Document URL"
                        keyName="external_document_url"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
          </section>
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                inputs.name === "" ||
                (inputs.contact_email !== "" &&
                  !validateEmail(inputs.contact_email.replaceAll(" ", ""))) ||
                (inputs.internal_email_list !== "" &&
                  (inputs.internal_email_list
                    .replaceAll(" ", "")
                    .split(/[;,]/)
                    .some((email: string) => !validateEmail(email)) ||
                    ((inputs.internal_email_list.match(/@/g) || []).length >
                      1 &&
                      !inputs.internal_email_list.includes(",") &&
                      !inputs.internal_email_list.includes(";")))) ||
                (inputs.primary_app_service !== "" &&
                  (inputs.primary_app_service
                    .replaceAll(" ", "")
                    .split(/[;,]/)
                    .some((email: string) => !validateEmail(email)) ||
                    ((inputs.primary_app_service.match(/@/g) || []).length >
                      1 &&
                      !inputs.primary_app_service.includes(",") &&
                      !inputs.primary_app_service.includes(";"))))
              }
              className="blue-button"
              onClick={() => {
                editVendor.mutate(
                  {
                    info: inputs,
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries(["get-vendor-metadata"]);

                      if (inputs.logo) {
                        const formData = new FormData();
                        formData.append("file", inputs.logo);
                        editVendorLogo.mutate({
                          vendorID: vendorID,
                          formData: formData,
                        });
                      }
                    },
                  }
                );
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UpdateVendor;
