/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { KeyStringVal } from "src/types/general";
import { GetControlBlueprints } from "../../../services/erc/controls/control-blueprints";
import React from "react";
import {
  AddBlueprintToEntityType,
  GetBlueprintsForEntityType,
  RemoveBlueprintFromEntityType,
} from "../../../services/erc/entities/entity-types";
import { useNavigate } from "react-router-dom";

const AssociateBlueprintsFilter = ({
  label,
  entityTypeID,
}: {
  label?: string;
  entityTypeID: string;
}) => {
  const navigate = useNavigate();

  const { data: blueprintsForEntityType } =
    GetBlueprintsForEntityType(entityTypeID);
  const { data: blueprints } = GetControlBlueprints();
  const addBlueprintToEntityType = AddBlueprintToEntityType();
  const removeBlueprintFromEntityType = RemoveBlueprintFromEntityType();

  return (
    <section className="flex flex-col flex-grow gap-3">
      <DropdownLayout
        label={label}
        value={`${blueprintsForEntityType?.length || 0} selected`}
        width="w-[10rem]"
        placeholder="Select Blueprint"
      >
        {blueprints?.data.map((blueprint: KeyStringVal, index: number) => {
          const included = blueprintsForEntityType?.some(
            (member) =>
              member.control_blueprint_id === blueprint.control_blueprint_id
          );

          return (
            <button
              key={index}
              className="relative flex items-center gap-2 px-4 py-1 w-full break-words text-left dark:disabled:hover:bg-transparent dark:hover:bg-gray-600/70 duration-100"
              onClick={() => {
                if (included)
                  removeBlueprintFromEntityType.mutate({
                    entityTypeID,
                    blueprint: [blueprint.control_blueprint_id],
                  });
                else
                  addBlueprintToEntityType.mutate({
                    entityTypeID,
                    blueprint: [blueprint.control_blueprint_id],
                  });
              }}
            >
              {included && (
                <FontAwesomeIcon icon={faCheck} className="text-no" />
              )}
              <p>{blueprint.name}</p>
            </button>
          );
        })}
      </DropdownLayout>
      {blueprintsForEntityType?.length > 0 && (
        <ul className="flex flex-wrap items-center gap-4 p-6 text-b1-reg dark:bg-gray-800 rounded-2xl">
          {blueprintsForEntityType?.map((blueprint: KeyStringVal) => {
            const included = blueprintsForEntityType?.some(
              (member) =>
                member.entity_type_id === blueprint.control_blueprint_id
            );
            return (
              <li
                key={blueprint.control_blueprint_id}
                className={`flex items-center gap-3 px-4 py-1 cursor-pointer dark:text-white ${
                  included
                    ? "dark:bg-blue-500"
                    : "dark:hover:bg-gray-700/30 duration-100"
                } dark:bg-gray-700 rounded`}
                onClick={() =>
                  navigate(
                    `/erc/controlblueprints/details?control_blueprint_id=${blueprint.control_blueprint_id}`
                  )
                }
              >
                <p>{blueprint.name}</p>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    removeBlueprintFromEntityType.mutate({
                      entityTypeID,
                      blueprint: [blueprint.control_blueprint_id],
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="w-4 h-4 dark:hover:text-gray-500 duration-100 rounded"
                  />
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
};

export default AssociateBlueprintsFilter;
