import React from "react";
import { convertToUTCShortString } from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetAllUsers } from "../../../../../services/settings/users";
import { getCustomerID } from "../../../../../utils/general";

const ControlBlueprintMetadata = ({ blueprint }: { blueprint: any }) => {
  const customerID = getCustomerID();

  const { data: allUsers } = GetAllUsers(customerID, false);

  const owner =
    allUsers?.find((user) => user.user_id === blueprint?.owner)?.user_email ||
    "";

  return (
    <>
      {blueprint && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            <h4 className="text-t1-semi">Control Blueprint</h4>
            <header className="grid content-start gap-1">
              <h2 className="text-subt1-semi">{blueprint.name}</h2>
              <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                <h4>last update at</h4>
                <span>
                  {convertToUTCShortString(blueprint.last_updated_at)}
                </span>
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-2 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[blueprint.status.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[blueprint.status.toLowerCase()]
                  }`}
                >
                  {blueprint.status}
                </span>
              </article>
              {blueprint.create_controls_automatically_enabled && (
                <article
                  className={`flex items-center gap-1 ${attributeColors.true}`}
                >
                  Create Controls Automatically
                </article>
              )}
            </section>
            <p>{blueprint.description}</p>
            <section className="grid grid-cols-5 gap-x-20 gap-y-10">
              {blueprint.type && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Type</h4>
                  <span className="text-b1-semi">{blueprint.type}</span>
                </article>
              )}
              {blueprint.category && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Category</h4>
                  <span className="text-b1-semi">{blueprint.category}</span>
                </article>
              )}
              {blueprint.sub_category && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Subcategory
                  </h4>
                  <span className="text-b1-semi">{blueprint.sub_category}</span>
                </article>
              )}
              {blueprint.classification && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Classification
                  </h4>
                  <span className="text-b1-semi">
                    {blueprint.classification}
                  </span>
                </article>
              )}
              {blueprint.attestation && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Attestation
                  </h4>
                  <span className="text-b1-semi">{blueprint.attestation}</span>
                </article>
              )}
              {owner && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Owner</h4>
                  <span className="text-b1-semi">{owner}</span>
                </article>
              )}
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default ControlBlueprintMetadata;
