import React from "react";

const CheckboxInput = ({
  label,
  keyName,
  inputs,
  setInputs,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: (inputs: any) => void;
}) => {
  return (
    <article className="flex items-center gap-2 text-b2-reg">
      <input
        type="checkbox"
        checked={inputs[keyName]}
        className="form-checkbox w-4 h-4 border-0 dark:focus:ring-0 dark:text-blue-600 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50"
        onChange={() => {
          setInputs({
            ...inputs,
            [keyName]: !inputs[keyName],
          });
        }}
      />
      <label>{label}</label>
    </article>
  );
};

export default CheckboxInput;
