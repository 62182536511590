/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FilterLayout from "src/layouts/FilterLayout";
import { GetAuditTrailUsers } from "src/services/regulation-policy/overview";
import { KeyStringVal } from "src/types/general";
import { getCustomerID } from "src/utils/general";

const AuditTrailUsersFilter = ({
  inputs,
  setInputs,
}: {
  inputs: string[];
  setInputs: (inputs: string[]) => void;
}) => {
  const customerID = getCustomerID();

  const { data: auditTrailUsers } = GetAuditTrailUsers(customerID);

  const handleSelect = (user: any) => {
    if (user === "All" && auditTrailUsers?.length > 0) {
      if (inputs.length < auditTrailUsers.length) {
        const userIDs = auditTrailUsers?.reduce(
          (pV: string[], cV: KeyStringVal) => [...pV, cV.user_id],
          []
        );
        setInputs(userIDs);
      } else setInputs([]);
    } else if (inputs.includes(user.user_id))
      setInputs(inputs.filter((userID: string) => userID !== user.user_id));
    else setInputs([...inputs, user.user_id]);
  };

  return (
    <FilterLayout label="Users" showOnLeft>
      <ul className="flex flex-col flex-grow gap-1">
        <li className="flex items-center gap-2 w-full">
          <input
            type="checkbox"
            className="form-checkbox w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
            checked={inputs.length === auditTrailUsers?.length}
            onClick={() => handleSelect("All")}
          />
          <label htmlFor="" className="w-max">
            All
          </label>
        </li>
        {auditTrailUsers?.map((option: KeyStringVal) => {
          return (
            <li key={option.user_id} className="flex items-center gap-2 w-full">
              <input
                type="checkbox"
                className="form-checkbox w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                checked={inputs.includes(option.user_id)}
                onClick={() => handleSelect(option)}
              />
              <label htmlFor="" className="w-max">
                {option.user_email}
              </label>
            </li>
          );
        })}
      </ul>
    </FilterLayout>
  );
};

export default AuditTrailUsersFilter;
