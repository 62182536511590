import React from "react";
import {
  convertToUTCShortString,
  getCustomerID,
  getEmailFromID,
} from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetEntityTypeMetadata } from "../../../../../services/erc/entities/entity-types";
import { GetAllUsers } from "src/services/settings/users";

const EntityTypeMetadata = ({ entityTypeID }: { entityTypeID: string }) => {
  const customerID = getCustomerID();

  const { data: entityTypeMetadata } = GetEntityTypeMetadata(entityTypeID);
  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <>
      {entityTypeMetadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="grid content-start gap-1">
              <h2 className="text-subt1-semi">{entityTypeMetadata.name}</h2>
              <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                <h4>
                  created by{" "}
                  {getEmailFromID(allUsers, entityTypeMetadata.created_by)}
                </h4>
                <span>
                  at {convertToUTCShortString(entityTypeMetadata.created_at)}
                </span>
              </article>
              <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                <h4>
                  last updated by{" "}
                  {getEmailFromID(allUsers, entityTypeMetadata.last_updated_by)}
                </h4>
                <span>
                  at{" "}
                  {convertToUTCShortString(entityTypeMetadata.last_updated_at)}
                </span>
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-2 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[entityTypeMetadata.status.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[entityTypeMetadata.status.toLowerCase()]
                  }`}
                >
                  {entityTypeMetadata.status}
                </span>
              </article>
            </section>
            <p>{entityTypeMetadata.description}</p>
            <section className="grid grid-cols-5 gap-x-20 gap-y-10">
              {entityTypeMetadata.entity_type && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Entity Type
                  </h4>
                  <span className="text-b1-semi">
                    {entityTypeMetadata.entity_type}
                  </span>
                </article>
              )}
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default EntityTypeMetadata;
