import React from "react";
import {
  ArchiveEntity,
  RestoreEntity,
} from "../../../../../services/erc/entities/entities";
import { attributeColors } from "../../../../../constants/general";

const ArchiveRestoreEntity = ({
  entityID,
  entity,
}: {
  entityID: string;
  entity: any;
}) => {
  const archiveEntity = ArchiveEntity(entityID);
  const restoreEntity = RestoreEntity(entityID);

  return (
    <nav className="flex items-center gap-1 p-1 w-max dark:bg-gray-900 rounded-lg">
      {["active", "archive"].map((tab) => {
        return (
          <button
            key={tab}
            className={`px-4 py-1 text-center capitalize ${
              entity.status.toLowerCase() === tab
                ? `${attributeColors[entity.status]}`
                : "dark:text-gray-500 dark:hover:bg-gray-500/30 duration-100"
            } rounded-lg`}
            onClick={(e) => {
              e.stopPropagation();
              if (tab === "archive") archiveEntity.mutate({});
              else restoreEntity.mutate({});
            }}
          >
            {entity.status.toLowerCase() === "draft" && tab === "active"
              ? "activate"
              : entity.status.toLowerCase() === "archived" && tab === "active"
              ? "recover"
              : tab}
          </button>
        );
      })}
    </nav>
  );
};

export default ArchiveRestoreEntity;
