/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import NewControlBlueprint from "./ControlBlueprints/NewControlBlueprint";
import ControlBlueprints from "./ControlBlueprints/ControlBlueprints";
import NewControl from "./Controls/NewControl";
import FrameworkControls from "./FrameworkControls/FrameworkControls";
import ControlsLanding from "./Controls/ControlsLanding";

const Controls = ({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: string;
  setSelectedTab: (selectedTab: string) => void;
}) => {
  useEffect(() => {
    setSelectedTab("framework controls");
  }, []);

  return (
    <section className="flex flex-col flex-grow gap-5">
      <header className="flex items-center justify-between gap-10 text-b1-reg">
        <nav className="flex flex-wrap items-center">
          {["framework controls", "control blueprints", "controls"].map(
            (tab) => {
              return (
                <button
                  key={tab}
                  className={`px-8 py-2 text-center capitalize ${
                    selectedTab === tab
                      ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                      : "dark:text-gray-500 dark:hover:text-white"
                  }`}
                  onClick={() => setSelectedTab(tab)}
                >
                  {tab}
                </button>
              );
            }
          )}
        </nav>
        {selectedTab === "control blueprints" ? (
          <NewControlBlueprint />
        ) : selectedTab === "controls" ? (
          <NewControl />
        ) : null}
      </header>
      {selectedTab === "framework controls" ? (
        <FrameworkControls />
      ) : selectedTab === "control blueprints" ? (
        <ControlBlueprints />
      ) : selectedTab === "controls" ? (
        <ControlsLanding />
      ) : null}
    </section>
  );
};

export default Controls;
