import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Source = ({
  sourceIndex,
  source,
  selectedHighlight,
  setSelectedHighlight,
}: {
  sourceIndex: number;
  source: any;
  selectedHighlight: string;
  setSelectedHighlight: (selectedHighlight: string) => void;
}) => {
  return (
    <article
      key={sourceIndex}
      className={`grid gap-5 p-3 w-full cursor-pointer ${
        selectedHighlight === source.id
          ? ""
          : "dark:hover:bg-gray-900/60 duration-100"
      } dark:bg-gray-900 rounded`}
      onClick={() => setSelectedHighlight(source.id || source.generated_id)}
    >
      <Disclosure>
        {({ open }) => {
          return (
            <section className="text-sm">
              <Disclosure.Button className="flex items-center gap-2">
                <FontAwesomeIcon icon={open ? faChevronDown : faChevronRight} />
                <h4>{open ? "Hide" : "Show"} content</h4>
              </Disclosure.Button>
              <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel>
                  <ReactMarkdown
                    className="px-6 py-4 text-b2-reg min-w-full prose dark:prose-invert dark:bg-gray-800 rounded-xl"
                    remarkPlugins={[remarkGfm]}
                  >
                    {source.content}
                  </ReactMarkdown>
                </Disclosure.Panel>
              </Transition>
            </section>
          );
        }}
      </Disclosure>
    </article>
  );
};

export default Source;
