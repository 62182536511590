/* eslint-disable no-restricted-globals */
import { faXmark, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import React from "react";
import { Fragment } from "react";
import {
  GetDocumentTags,
  AddDocumentTag,
  RemoveDocumentTag,
} from "src/services/grc";
import { KeyStringVal } from "src/types/general";

const DocumentTags = ({
  documentType,
  documentID,
}: {
  documentType: string;
  documentID: string;
}) => {
  const { data: allTags } = GetDocumentTags(documentType);
  const { data: documentTags } = GetDocumentTags(documentType, "", documentID);
  const addDocumentTag = AddDocumentTag(documentType);
  const deleteDocumentTag = RemoveDocumentTag(documentType);

  return (
    <section className="flex flex-wrap items-center gap-4 p-6 text-b1-reg dark:bg-gray-800 black-shadow rounded-2xl">
      {documentTags?.length > 0 && (
        <ul className="flex flex-wrap items-center gap-3">
          {documentTags.map((tag: KeyStringVal) => {
            return (
              <li
                key={tag.tag_id}
                className="flex items-center gap-3 px-4 py-1 border dark:border-blue-400 rounded"
              >
                <p>{tag.tag_title}</p>

                {/* remove tag */}
                <button
                  onClick={() =>
                    deleteDocumentTag.mutate({
                      documentID: documentID,
                      tags: [tag.tag_id],
                    })
                  }
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="w-4 h-4 dark:hover:text-gray-500 duration-100 rounded"
                  />
                </button>
              </li>
            );
          })}
        </ul>
      )}

      <Menu as="article" className="relative inline-block text-left">
        <Menu.Button className="flex items-center gap-2 px-2 py-1 dark:bg-blue-600 dark:hover:bg-blue-600/70 duration-100 rounded">
          <FontAwesomeIcon icon={faPlus} />
          <h4>Assign Tag</h4>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 bottom-10 grid w-max max-h-[10rem] gap-2 origin-top-right focus:outline-none dark:text-white dark:bg-blue-600 rounded">
            {allTags?.length > 0 ? (
              <nav className="grid content-start w-full h-full overflow-auto scrollbar">
                {allTags?.map((tag: KeyStringVal) => {
                  return (
                    <button
                      key={tag.tag_id}
                      className="flex items-center gap-2 px-4 py-2 w-full text-left dark:hover:bg-blue-500/70 duration-100 rounded"
                      onClick={() =>
                        addDocumentTag.mutate({
                          documentID: documentID,
                          tags: [tag.tag_id],
                        })
                      }
                    >
                      <p className="grid text-xs">{tag.tag_title}</p>
                    </button>
                  );
                })}
              </nav>
            ) : (
              <section className="grid gap-2 px-5 py-3 w-max origin-top-right focus:outline-none dark:text-white dark:bg-blue-600 rounded">
                <h4>No new tags</h4>
              </section>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </section>
  );
};

export default DocumentTags;
