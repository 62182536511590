import { useQuery, useMutation } from "react-query";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { queryClient } from "src/App";

const blueprintPrefix = "grc/grc-controls/blueprints";
const frameworkControlPrefix = "grc/grc-controls/framework-controls";

export const GetControlBlueprints = (
  pageNumber?: number,
  tags?: string[] | undefined,
  groups?: string[]
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-control-blueprints", pageNumber, tags, groups],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${blueprintPrefix}/getall`,
          pageNumber
            ? {
                page_number: pageNumber,
                page_size: pageSize,
              }
            : null,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const AddControlBlueprint = () =>
  useMutation<any, unknown, any, string>(
    async ({ info, signal }: { info: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${blueprintPrefix}`,
          info,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-control-blueprints"]),
    }
  );

export const GetControlBlueprintMetadata = (blueprintID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-control-blueprint-metadata", blueprintID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${blueprintPrefix}/${blueprintID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: blueprintID !== "",
      keepPreviousData: false,
    }
  );

export const GetSourceNames = () =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-source-names"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${frameworkControlPrefix}/frameworks`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetSourceControls = (frameworkID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-source-controls", frameworkID],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${frameworkControlPrefix}/frameworks/${frameworkID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: frameworkID !== "",
      keepPreviousData: false,
    }
  );

export const GetEntityTypesForBlueprint = (
  blueprintID: string,
  pageNumber?: number,
  tags?: string[] | undefined,
  groups?: string[]
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-entity-types-for-blueprint", blueprintID, pageNumber, tags, groups],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${blueprintPrefix}/${blueprintID}/entity-types`,
          pageNumber
            ? {
                page_number: pageNumber,
                page_size: pageSize,
              }
            : null,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: blueprintID !== "",
      keepPreviousData: false,
    }
  );

export const AddEntityTypeToBlueprint = () =>
  useMutation<any, unknown, any, string>(
    async ({
      blueprintID,
      entityType,
      signal,
    }: {
      blueprintID: string;
      entityType: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${blueprintPrefix}/${blueprintID}/entity-types/add`,
          entityType,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-entity-types-for-blueprint"]);
        queryClient.invalidateQueries(["get-control-blueprint-metadata"]);
        queryClient.invalidateQueries(["get-entity-types"]);
      },
    }
  );

export const RemoveEntityTypeFromBlueprint = () =>
  useMutation<any, unknown, any, string>(
    async ({
      blueprintID,
      entityType,
      signal,
    }: {
      blueprintID: string;
      entityType: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${blueprintPrefix}/${blueprintID}/entity-types/remove`,
          entityType,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-entity-types-for-blueprint"]);
        queryClient.invalidateQueries(["get-control-blueprint-metadata"]);
        queryClient.invalidateQueries(["get-entity-types"]);
      },
    }
  );
