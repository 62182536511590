import React from "react";

const ControlContent = ({ subsection }: { subsection: any }) => {
  return (
    <section className="grid gap-5 text-b1-reg">
      <p className="grid gap-2 w-full dark:text-gray-400">
        {subsection.content.split("\n").map((phrase: string, index: number) => (
          <span
            key={index}
            className="flex flex-wrap items-center gap-1 w-full"
          >
            {phrase.split(" ").map((word, wordIndex) => (
              <span
                key={wordIndex}
                className={`${
                  subsection.search_highlight?.matched_tokens?.includes(word)
                    ? "text-black bg-yellow-300"
                    : ""
                }`}
              >
                {word}
              </span>
            ))}
          </span>
        ))}
      </p>
    </section>
  );
};

export default ControlContent;
