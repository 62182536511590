/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { Fragment, useState } from "react";
import Policies from "../Policies";
import { GetPolicyGroups } from "src/services/regulation-policy/policy";
import { KeyStringVal } from "src/types/general";
import EditPolicyGroup from "./EditPolicyGroup";
import DeletePolicyGroup from "./DeletePolicyGroup";
import CreatePolicyGroup from "./CreatePolicyGroup";
import NewPolicy from "../NewPolicy";
import Loader from "src/components/Loader/Loader";
import CompareAgainstFramework from "../CompareAgainstFramework/CompareAgainstFramework";
import { faEye, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GeneratePolicy from "../GeneratePolicy";

const PolicyGroups = () => {
  const [selectedPolicyGroup, setSelectedPolicyGroup] =
    useState<boolean>(false);
  const [compareAgainst, setCompareAgainst] = useState<boolean>(false);
  const [showGeneratePolicy, setShowGeneratePolicy] = useState<boolean>(false);

  const { data: policyGroups, status: policyGroupStatus } = GetPolicyGroups();
  const policyGroup = policyGroups?.find(
    (group: KeyStringVal) =>
      group.policy_group_id === sessionStorage.selectedPolicyGroupID
  )?.title;

  return (
    <section className="flex flex-col flex-grow gap-5 py-4 w-full h-full">
      {sessionStorage.compareAgainstFramework ? (
        <CompareAgainstFramework
          compareAgainst={compareAgainst}
          setCompareAgainst={setCompareAgainst}
        />
      ) : !sessionStorage.selectedPolicyGroupID ? (
        <section className="flex flex-col flex-grow gap-5 w-full min-h-full">
          <header className="flex flex-wrap items-center justify-between gap-5">
            <h4 className="flex items-center gap-2 text-subt1-semi">
              Policy Groups <span>({policyGroups?.length})</span>
            </h4>
            <article className="flex flex-wrap items-center gap-3">
              <button
                className="small-black-button"
                onClick={() => {
                  sessionStorage.compareAgainstFramework = "true";
                  setCompareAgainst(!compareAgainst);
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 15H1C0.734784 15 0.48043 14.8946 0.292893 14.7071C0.105357 14.5196 0 14.2652 0 14V2C0 1.73478 0.105357 1.48043 0.292893 1.29289C0.48043 1.10536 0.734784 1 1 1H6C6.26522 1 6.51957 1.10536 6.70711 1.29289C6.89464 1.48043 7 1.73478 7 2V14C7 14.2652 6.89464 14.5196 6.70711 14.7071C6.51957 14.8946 6.26522 15 6 15Z"
                    fill="white"
                  />
                  <path d="M11 6H9V10H11V6Z" fill="white" />
                  <path
                    d="M14 13H11V12H9V14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15H15C15.2652 15 15.5196 14.8946 15.7071 14.7071C15.8946 14.5196 16 14.2652 16 14V12H14V13Z"
                    fill="white"
                  />
                  <path
                    d="M15 1H10C9.73478 1 9.48043 1.10536 9.29289 1.29289C9.10536 1.48043 9 1.73478 9 2V4H11V3H14V4H16V2C16 1.73478 15.8946 1.48043 15.7071 1.29289C15.5196 1.10536 15.2652 1 15 1Z"
                    fill="white"
                  />
                  <path d="M16 6H14V10H16V6Z" fill="white" />
                </svg>
                Compare against Framework
              </button>
              <NewPolicy />
              <button
                className="small-black-button"
                onClick={() => setShowGeneratePolicy(!showGeneratePolicy)}
              >
                <FontAwesomeIcon icon={showGeneratePolicy ? faMinus : faPlus} />
                <h4>Generate Policy</h4>
              </button>
              <CreatePolicyGroup />
            </article>
          </header>

          {showGeneratePolicy ? (
            <GeneratePolicy
              selectedPolicyGroup={selectedPolicyGroup}
              setSelectedPolicyGroup={setSelectedPolicyGroup}
              setShowGeneratePolicy={setShowGeneratePolicy}
            />
          ) : (
            <>
              {policyGroupStatus === "loading" ? (
                <Loader />
              ) : policyGroups?.length > 0 ? (
                <ul className="flex flex-col flex-grow gap-5 pb-20">
                  {policyGroups?.map((policyGroup: KeyStringVal) => {
                    return (
                      <li
                        key={policyGroup.policy_group_id}
                        className="grid gap-4 px-6 py-4 dark:bg-gray-800 rounded-xl"
                      >
                        <article className="flex flex-wrap justify-between gap-10">
                          <h4 className="text-subt1-semi">
                            {policyGroup.title}
                          </h4>
                          <article className="flex flex-wrap items-center gap-10 text-b2-reg">
                            {!["default", "all"].includes(
                              policyGroup.title.toLowerCase()
                            ) && (
                              <>
                                <EditPolicyGroup
                                  policyGroupID={policyGroup.policy_group_id}
                                />
                                <DeletePolicyGroup
                                  policyGroupID={policyGroup.policy_group_id}
                                />
                              </>
                            )}
                            <button
                              className="flex items-center gap-2 dark:text-blue-600 dark:hover:text-blue-600/70 duration-100"
                              onClick={() => {
                                setSelectedPolicyGroup(!selectedPolicyGroup);
                                sessionStorage.selectedPolicyGroupID =
                                  policyGroup.policy_group_id;
                                sessionStorage.selectedPolicyGroupName =
                                  policyGroup.title;
                              }}
                            >
                              <FontAwesomeIcon icon={faEye} /> View policies{" "}
                            </button>
                          </article>
                        </article>
                        <p className="text-b1-reg">{policyGroup.description}</p>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <section className="flex items-center place-content-center gap-10 w-full h-full">
                  <img
                    src="/grc/policies-placeholder.svg"
                    alt="policies placeholder"
                    className="w-40 h-40"
                  />
                  <article className="grid gap-3">
                    <h4 className="text-t1-bold">Policies</h4>
                    <h4>No policy groups available</h4>
                  </article>
                </section>
              )}
            </>
          )}
        </section>
      ) : (
        <Policies
          policyGroup={policyGroup}
          selectedPolicyGroup={selectedPolicyGroup}
          setSelectedPolicyGroup={setSelectedPolicyGroup}
        />
      )}
    </section>
  );
};

export default PolicyGroups;
