/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  GetPolicies,
  GetPolicyGroups,
} from "src/services/regulation-policy/policy";
import Policies from "./Policies";
import { KeyStringVal } from "src/types/general";

const PolicyGroups = ({
  selectedPolicyIDs,
  setSelectedPolicyIDs,
}: {
  selectedPolicyIDs: string[];
  setSelectedPolicyIDs: (selectedPolicyIDs: string[]) => void;
}) => {
  const { data: policyGroups } = GetPolicyGroups();
  const { data: policies } = GetPolicies();

  useEffect(() => {
    if (policies?.data?.length > 0) {
      const policyIDs = policies?.data?.reduce(
        (pV: string[], cV: KeyStringVal) => [...pV, cV.policy_id],
        []
      );
      setSelectedPolicyIDs([...selectedPolicyIDs, ...policyIDs]);
    }
  }, [policies]);

  return (
    <section className="flex flex-col flex-grow">
      <header className="flex items-center gap-4 p-6 text-subt1-semi dark:bg-gray-700 rounded-t-xl">
        <input
          type="checkbox"
          checked={selectedPolicyIDs?.length === policies?.data?.length}
          onChange={() => {
            if (selectedPolicyIDs?.length === policies?.data?.length)
              setSelectedPolicyIDs([]);
            else {
              const policyIDs = policies?.data?.reduce(
                (pV: string[], cV: KeyStringVal) => [...pV, cV.policy_id],
                []
              );
              setSelectedPolicyIDs(policyIDs);
            }
          }}
          className="form-checkbox w-4 h-4 border-0 dark:focus:ring-0 dark:text-blue-600 dark:focus:border-white focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-500 focus:ring-opacity-50"
        />
        <h4>Policy Groups</h4>
      </header>
      <ul className="flex flex-col flex-grow gap-5 p-6 mb-20 dark:bg-gray-800 divide-y dark:divide-gray-700 rounded-b-xl">
        {policyGroups?.map((policyGroup: KeyStringVal) => {
          return (
            <article
              key={policyGroup.policy_group_id}
              className="grid gap-3 first:pt-0 pt-5"
            >
              <Policies
                policyGroup={policyGroup}
                policyGroupID={policyGroup.policy_group_id}
                selectedPolicyIDs={selectedPolicyIDs}
                setSelectedPolicyIDs={setSelectedPolicyIDs}
              />
            </article>
          );
        })}
      </ul>
    </section>
  );
};

export default PolicyGroups;
