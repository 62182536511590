import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Markdown from "react-markdown";
import { UpdatePolicySections } from "src/services/regulation-policy/policy";

const SubsectionContent = ({
  documentType,
  documentID,
  subsection,
  filter,
  editSections,
  setEditSections,
}: {
  documentType: string;
  documentID: string;
  subsection: any;
  filter?: string;
  editSections?: any;
  setEditSections?: any;
}) => {
  const isPolicy = documentType === "policies";

  const updatePolicySections = UpdatePolicySections();

  return (
    <section className="text-b1-reg">
      {editSections &&
      Object.keys(editSections).includes(subsection.generated_id) ? (
        <section className="grid gap-2">
          <article className="flex items-center place-content-end gap-5">
            <button
              className="red-button"
              onClick={() =>
                setEditSections(
                  Object.fromEntries(
                    Object.entries(editSections).filter(
                      ([key]) => key !== subsection.generated_id
                    )
                  )
                )
              }
            >
              Discard
            </button>
            {subsection.content !==
              editSections[subsection.generated_id].content && (
              <button
                className="blue-button"
                onClick={() => {
                  updatePolicySections.mutate({
                    policyID: documentID,
                    editSections: editSections,
                  });
                  setEditSections({});
                }}
              >
                Save
              </button>
            )}
          </article>

          <textarea
            spellCheck="false"
            autoComplete="off"
            value={editSections[subsection.generated_id]?.content}
            onChange={(e) =>
              setEditSections({
                ...editSections,
                [subsection.generated_id]: {
                  content: e.target.value,
                },
              })
            }
            className="px-4 py-2 w-full h-40 focus:outline-none text-b2-reg placeholder:text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 rounded"
          />
        </section>
      ) : (
        <section className="grid gap-2">
          <Markdown>{subsection.content}</Markdown>
          {isPolicy &&
            editSections &&
            !editSections[subsection.generated_id]?.content &&
            filter !== "Suggest New Mapping" && (
              <button
                className="flex items-center gap-2 dark:hover:text-blue-500/70 duration-100"
                onClick={() =>
                  setEditSections({
                    ...editSections,
                    [subsection.generated_id]: {
                      content: subsection.content,
                    },
                  })
                }
              >
                <FontAwesomeIcon icon={faEdit} />
                Edit
              </button>
            )}
        </section>
      )}
    </section>
  );
};

export default SubsectionContent;
