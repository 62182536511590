/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { KeyStringVal } from "src/types/general";
import {
  AddEntityTypeToBlueprint,
  GetEntityTypesForBlueprint,
  RemoveEntityTypeFromBlueprint,
} from "../../../services/erc/controls/control-blueprints";
import { GetEntityTypes } from "../../../services/erc/entities/entity-types";
import React from "react";

const AssociateEntityTypeFilter = ({
  label,
  blueprintID,
}: {
  label?: string;
  blueprintID: string;
}) => {
  const { data: entityTypesForBlueprint } =
    GetEntityTypesForBlueprint(blueprintID);
  const { data: entityTypes } = GetEntityTypes();
  const addEntityTypeToBlueprint = AddEntityTypeToBlueprint();
  const removeEntityTypeFromBlueprint = RemoveEntityTypeFromBlueprint();

  return (
    <section className="flex flex-col flex-grow gap-3">
      <DropdownLayout
        label={label}
        value={`${entityTypesForBlueprint?.length || 0} selected`}
        width="w-[10rem]"
        placeholder="Select Entity Type"
      >
        {entityTypes?.data.map((entityType: KeyStringVal, index: number) => {
          const included = entityTypesForBlueprint?.some(
            (member) => member.entity_type_id === entityType.entity_type_id
          );

          return (
            <button
              key={index}
              className="relative flex items-center gap-2 px-4 py-1 w-full break-words text-left dark:disabled:hover:bg-transparent dark:hover:bg-gray-600/70 duration-100"
              onClick={() => {
                if (included)
                  removeEntityTypeFromBlueprint.mutate({
                    blueprintID,
                    entityType: [entityType.entity_type_id],
                  });
                else
                  addEntityTypeToBlueprint.mutate({
                    blueprintID,
                    entityType: [entityType.entity_type_id],
                  });
              }}
            >
              {included && (
                <FontAwesomeIcon icon={faCheck} className="text-no" />
              )}
              <p>{entityType.name}</p>
            </button>
          );
        })}
      </DropdownLayout>
      {entityTypesForBlueprint?.length > 0 && (
        <ul className="flex flex-wrap items-center gap-4 p-6 text-b1-reg dark:bg-gray-800 rounded-2xl">
          {entityTypesForBlueprint?.map((entityType: KeyStringVal) => {
            const included = entityTypesForBlueprint?.some(
              (member) => member.entity_type_id === entityType.entity_type_id
            );
            return (
              <li
                key={entityType.entity_type_id}
                className={`flex items-center gap-3 px-4 py-1 cursor-pointer dark:text-white ${
                  included
                    ? "dark:bg-blue-500"
                    : "dark:hover:bg-gray-700/30 duration-100"
                } dark:bg-gray-700 rounded`}
              >
                <p>{entityType.name}</p>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    removeEntityTypeFromBlueprint.mutate({
                      blueprintID,
                      entityType: [entityType.entity_type_id],
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    className="w-4 h-4 dark:hover:text-gray-500 duration-100 rounded"
                  />
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
};

export default AssociateEntityTypeFilter;
