import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import {
  AddVendor,
  GetVendorFieldValues,
} from "src/services/third-party-risk/vendors/vendors";
import {
  AddVendorLogo,
  GetVendorsAndPartners,
} from "../../../../services/third-party-risk/vendors/vendors";
import { KeyStringVal } from "../../../../types/general";
import LogoInput from "src/components/Input/LogoInput";
import { queryClient } from "src/App";
import CategoryInput from "../../../../components/Input/CategoryInput";
import { decodeJWT, validateEmail } from "../../../../utils/general";
import ToastLayout from "../../../../layouts/ToastLayout";
import CheckboxInput from "src/components/Input/CheckboxInput";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import MultiCategoryInput from "src/components/Input/MultiCategoryInput";
import { Disclosure } from "@headlessui/react";
import UserInput from "src/components/Input/UserInput";

const NewVendor = () => {
  const jwt = decodeJWT();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    inherent_risk: "critical",
    address: "",
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    number_of_employees: "0",
    internal_email_list: "",
    business_user_name: "",
    department_head: "",
    location: "",
    department: "",
    vendor_type: "",
    vendor_manager: null,
    vendor_tier: "",
    rank_tier: "",
    website: "",
    duns_number: "",
    status: "",
    logo: "",
    is_publicly_traded: false,
    notes: "",
    workflow: "",
    stage: "",
    created_by: "",
    dj_nth_party_risks: "",
    help_ticket: "",
    sso_enabled: "",
    is_PHI: false,
    is_PII: false,
    authentication_protocol: "",
    data_host_location: "",
    primary_app_service: "",
    impacted_systems: "",
    review_checklist: [],
    description: "",
    additional_notes: "",
    sponsor: "",
    data_classification: "",
    baa_executed: "",
    creation_date: Date.now() * 1000,
    assessed_for_ai_functionality: "",
    last_updated_by: "",
    ref_id: "",
    last_review_date: Date.now() * 1000,
    next_review_date: Date.now() * 1000,
    reassessment_frequency: "",
    target_primary_application_service: "",
    additional_information: "",
    analyst_comments: "",
    criticality: "",
    historical_data: "",
    external_document_url: "",
  });
  const [valid, setValid] = useState<boolean>(true);

  const { data: vendors } = GetVendorsAndPartners(1);
  const addVendor = AddVendor();
  const addVendorLogo = AddVendorLogo();

  const { data: ssoEnabled } = GetVendorFieldValues("sso_enabled");
  const { data: dataClassification } = GetVendorFieldValues(
    "data_classification"
  );
  const { data: reassessmentFrequency } = GetVendorFieldValues(
    "reassessment_frequency"
  );
  const { data: authenticationProtocol } = GetVendorFieldValues(
    "authentication_protocol"
  );
  const { data: reviewChecklist } = GetVendorFieldValues("review_checklist");
  const { data: baaExecuted } = GetVendorFieldValues("baa_executed");

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addVendor.reset();

  return (
    <>
      <button
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            inherent_risk: "critical",
            address: "",
            contact_name: "",
            contact_email: "",
            contact_phone: "",
            number_of_employees: "0",
            internal_email_list: "",
            business_user_name: "",
            department_head: "",
            location: "",
            department: "",
            vendor_type: "",
            vendor_manager: null,
            vendor_tier: "",
            rank_tier: "",
            website: "",
            duns_number: "",
            status: "",
            logo: "",
            is_publicly_traded: false,
            notes: "",
            workflow: "",
            stage: "",
            created_by: jwt?.sub,
            dj_nth_party_risks: "",
            help_ticket: "",
            sso_enabled: "",
            is_PHI: false,
            is_PII: false,
            authentication_protocol: "",
            data_host_location: "",
            primary_app_service: "",
            impacted_systems: "",
            review_checklist: [],
            description: "",
            additional_notes: "",
            sponsor: "",
            data_classification: "",
            baa_executed: "",
            creation_date: Date.now() * 1000,
            assessed_for_ai_functionality: "",
            last_updated_by: jwt?.sub,
            ref_id: "",
            last_review_date: Date.now() * 1000,
            next_review_date: Date.now() * 1000,
            reassessment_frequency: "",
            target_primary_application_service: "",
            additional_information: "",
            analyst_comments: "",
            criticality: "",
            historical_data: "",
            external_document_url: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Vendor</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 w-full h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Vendor</h3>
          <section className="grid gap-10 w-full h-full">
            <LogoInput
              label="Logo"
              keyName="logo"
              types={["jpeg", "png", "svg"]}
              inputs={inputs}
              setInputs={setInputs}
            />
            <Disclosure defaultOpen>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">
                      Primary Vendor Information
                    </h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-2 content-start gap-5 w-full">
                      <RegularInput
                        label="Vendor"
                        keyName="name"
                        inputs={inputs}
                        setInputs={setInputs}
                        valid={valid}
                        setValid={setValid}
                        required
                      />
                      <RegularInput
                        label="Vendor Type"
                        keyName="vendor_type"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CategoryInput
                        label="Assessed for AI Functionality"
                        keyName="assessed_for_ai_functionality"
                        list={["N/A", "No", "Yes"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <RegularInput
                        label="Website"
                        keyName="website"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Address"
                        keyName="address"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Number of Employees"
                        keyName="number_of_employees"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Ref-ID"
                        keyName="ref_id"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Description"
                        keyName="description"
                        inputs={inputs}
                        setInputs={setInputs}
                        textarea
                      />
                      <CategoryInput
                        label="Status"
                        keyName="status"
                        list={["active", "inactive"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CategoryInput
                        label="Inherent Risk"
                        keyName="inherent_risk"
                        list={["critical", "high", "medium", "low"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CategoryInput
                        label="Rank Tier"
                        keyName="rank_tier"
                        list={["critical", "high", "medium", "low"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CheckboxInput
                        label="Publicly traded"
                        keyName="is_publicly_traded"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">Risk Management</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-2 gap-5">
                      <RegularInput
                        label="Application Owner Email"
                        keyName="primary_app_service"
                        inputs={inputs}
                        setInputs={setInputs}
                        multipleEmail
                      />
                      <UserInput
                        label="Vendor Manager Email"
                        keyName="vendor_manager"
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <RegularInput
                        label="Help Ticket"
                        keyName="help_ticket"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Vendor Sponsor"
                        keyName="sponsor"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CategoryInput
                        label="Criticality"
                        keyName="criticality"
                        list={["Partner", "Critical", "High", "Medium", "Low"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CategoryInput
                        label="Data Classification"
                        keyName="data_classification"
                        list={dataClassification?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <GeneralSnapshotDatepicker
                        label="Last Review Date"
                        keyName="last_review_date"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <GeneralSnapshotDatepicker
                        label="Next Review Date"
                        keyName="next_review_date"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CategoryInput
                        label="Reassessment Frequency"
                        keyName="reassessment_frequency"
                        list={reassessmentFrequency?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <RegularInput
                        label="Notes"
                        keyName="notes"
                        inputs={inputs}
                        setInputs={setInputs}
                        textarea
                      />
                      <RegularInput
                        label="Additional Notes"
                        keyName="additional_notes"
                        inputs={inputs}
                        setInputs={setInputs}
                        textarea
                      />
                      <RegularInput
                        label="Analyst Comments"
                        keyName="analyst_comments"
                        inputs={inputs}
                        setInputs={setInputs}
                        textarea
                      />
                      <MultiCategoryInput
                        label="Vendor Review Checklist"
                        keyName="review_checklist"
                        list={reviewChecklist?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <RegularInput
                        label="Additional Information"
                        keyName="additional_information"
                        inputs={inputs}
                        setInputs={setInputs}
                        textarea
                      />
                      <RegularInput
                        label="Impacted Systems"
                        keyName="impacted_systems"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CategoryInput
                        label="SSO Enabled"
                        keyName="sso_enabled"
                        list={ssoEnabled?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CategoryInput
                        label="Vendor Tier"
                        keyName="vendor_tier"
                        list={["critical", "high", "medium", "low"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CategoryInput
                        label="Business Associate Agreement Executed"
                        keyName="baa_executed"
                        list={baaExecuted?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">
                      Vendor Contact Information
                    </h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-2 gap-5">
                      <RegularInput
                        label="Contact Name"
                        keyName="contact_name"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Contact Email"
                        keyName="contact_email"
                        inputs={inputs}
                        setInputs={setInputs}
                        singleEmail
                      />
                      <RegularInput
                        label="Contact Phone"
                        keyName="contact_phone"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Location"
                        keyName="location"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Data Host Location"
                        keyName="data_host_location"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">Scoping Details</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-2 gap-5">
                      <RegularInput
                        label="Target Primary Application Service"
                        keyName="target_primary_application_service"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CategoryInput
                        label="Authentication Protocol"
                        keyName="authentication_protocol"
                        list={authenticationProtocol?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CheckboxInput
                        label="PHI"
                        keyName="is_PHI"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CheckboxInput
                        label="PII"
                        keyName="is_PII"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">Others</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-2 gap-5">
                      <RegularInput
                        label="Workflow"
                        keyName="workflow"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Dow Jones Nth Party Risks"
                        keyName="dj_n_party_risks"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Internal Email CC List"
                        keyName="internal_email_list"
                        inputs={inputs}
                        setInputs={setInputs}
                        multipleEmail
                      />
                      <RegularInput
                        label="Name of Business User"
                        keyName="business_user_name"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Head of Department"
                        keyName="department_head"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Department"
                        keyName="department"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Stage"
                        keyName="stage"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Historical Data"
                        keyName="historical_data"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="External Document URL"
                        keyName="external_document_url"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
          </section>
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                inputs.name === "" ||
                (inputs.contact_email !== "" &&
                  !validateEmail(inputs.contact_email.replaceAll(" ", ""))) ||
                (inputs.internal_email_list !== "" &&
                  (inputs.internal_email_list
                    .replaceAll(" ", "")
                    .split(/[;,]/)
                    .some((email: string) => !validateEmail(email)) ||
                    ((inputs.internal_email_list.match(/@/g) || []).length >
                      1 &&
                      !inputs.internal_email_list.includes(",") &&
                      !inputs.internal_email_list.includes(";")))) ||
                (inputs.primary_app_service !== "" &&
                  (inputs.primary_app_service
                    .replaceAll(" ", "")
                    .split(/[;,]/)
                    .some((email: string) => !validateEmail(email)) ||
                    ((inputs.primary_app_service.match(/@/g) || []).length >
                      1 &&
                      !inputs.primary_app_service.includes(",") &&
                      !inputs.primary_app_service.includes(";"))))
              }
              className="blue-button"
              onClick={() => {
                if (
                  vendors?.data.some(
                    (vendor: KeyStringVal) =>
                      vendor.name.toLowerCase().trim() ===
                      inputs.name.toLowerCase().trim()
                  )
                )
                  setValid(false);
                else {
                  addVendor.mutate(
                    {
                      info: inputs,
                    },
                    {
                      onSuccess: (data) => {
                        queryClient.invalidateQueries(["get-vendors"]);

                        if (inputs.logo) {
                          const vendorID = data?.slice(
                            data?.lastIndexOf(": ") + 2
                          );
                          const formData = new FormData();
                          formData.append("file", inputs.logo);
                          addVendorLogo.mutate({
                            vendorID: vendorID,
                            formData: formData,
                          });
                        }
                      },
                    }
                  );

                  handleOnClose();
                }
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addVendor.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New vendor has been created"
      />
    </>
  );
};

export default NewVendor;
