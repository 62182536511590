import React from "react";
import FilterLayout from "src/layouts/FilterLayout";

const Filters = ({
  filters,
  setFilters,
  isFramework,
}: {
  filters: any;
  setFilters: any;
  isFramework: boolean;
}) => {
  return (
    <FilterLayout label="Filters" showOnLeft>
      <ul className="flex flex-col flex-grow gap-1">
        <li className="flex items-center gap-2 w-full">
          <input
            type="checkbox"
            className="form-checkbox w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
            checked={filters.in_progress}
            onClick={() =>
              setFilters({
                ...filters,
                in_progress: !filters.in_progress,
              })
            }
          />
          <label className="w-max">In progress</label>
        </li>
        {!isFramework && (
          <li className="flex items-center gap-2 w-full">
            <input
              type="checkbox"
              className="form-checkbox w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
              checked={filters.mapped_to_policy === "MappedToPolicy"}
              onClick={() => {
                if (filters.mapped_to_policy === "")
                  setFilters({
                    ...filters,
                    mapped_to_policy: "MappedToPolicy",
                  });
                else setFilters({ ...filters, mapped_to_policy: "" });
              }}
            />
            <label className="w-max">Has mapping</label>
          </li>
        )}
      </ul>
    </FilterLayout>
  );
};

export default Filters;
