/* eslint-disable react-hooks/exhaustive-deps */
import {
  faCheck,
  faArrowRightLong,
  faXmark,
  faWarning,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import TextFilter from "src/components/Filter/General/TextFilter";
import { toastBorderColors } from "src/constants/general";
import { GetDocumentStatus, GetGRCDocumentMetadata } from "src/services/grc";
import {
  GetPolicyVersions,
  UpdatePolicyName,
} from "src/services/regulation-policy/policy";
import { KeyStringVal } from "src/types/general";
import { convertToUTCShortString, parseURL } from "src/utils/general";
import DeletePolicy from "../../Policies/DeletePolicy";
import DocumentTags from "./DocumentTags";
import RegionsVerticals from "./RegionsVerticals";
import UpdatePolicyVersion from "./UpdatePolicyVersion";
import { useNavigate } from "react-router-dom";
import DocumentSearch from "../DocumentDetail/DocumentSearch";

const DocumentMetadata = ({
  documentType,
  documentID,
  selectedPolicyVersion,
  setSelectedPolicyVersion,
  search,
  setSearch,
  query,
  setQuery,
}: {
  documentType: string;
  documentID: string;
  selectedPolicyVersion: string;
  setSelectedPolicyVersion: (selectedPolicyVersion: string) => void;
  search: boolean;
  setSearch: (search: boolean) => void;
  query: string;
  setQuery: (query: string) => void;
}) => {
  const parsed = parseURL();
  const navigate = useNavigate();

  const [editPolicyName, setEditPolicyName] = useState<boolean>(false);
  const [policyName, setPolicyName] = useState<string>("");
  const [isVisible, setVisible] = useState<boolean>(true);

  const { data: documentMetadata } = GetGRCDocumentMetadata(
    documentType,
    documentID
  );
  const { data: policyVersions } = GetPolicyVersions(documentType, documentID);
  const updatePolicyName = UpdatePolicyName();

  const versionID =
    policyVersions?.find(
      (version: KeyStringVal) => version.version === selectedPolicyVersion
    )?.version_id || "";

  const policyVersionID = String(parsed.policy_version_id) || "";
  const { data: documentStatus } = GetDocumentStatus(
    documentType,
    documentID,
    versionID
  );

  const documentName =
    documentMetadata?.framework_name || documentMetadata?.policy_name;
  const versions = policyVersions?.reduce(
    (pV: string[], cV: KeyStringVal) => [...pV, cV.version],
    []
  );
  const isPolicy = documentType === "policies";

  const handleEditPolicyName = () => {
    const formData = new FormData();

    formData.append("policy_name", policyName);
    updatePolicyName.mutate({
      policyID: documentID,
      formData: formData,
    });
    setEditPolicyName(false);
  };

  useEffect(() => {
    if (isPolicy && versions?.length > 0 && selectedPolicyVersion === "") {
      if (!parsed.policy_version_id) setSelectedPolicyVersion(versions[0]);
      else {
        const filteredPolicyVersion =
          policyVersions?.find(
            (version: KeyStringVal) => version.version_id === policyVersionID
          )?.version || "";
        setSelectedPolicyVersion(filteredPolicyVersion);
      }
    }
  }, [versions]);

  useEffect(() => {
    if (selectedPolicyVersion !== "") {
      const filteredPolicyVersionID = policyVersions?.find(
        (version: KeyStringVal) => version.version === selectedPolicyVersion
      )?.version_id;
      navigate(
        `${window.location.pathname}?document_type=${parsed.document_type}&document_id=${parsed.document_id}&policy_version_id=${filteredPolicyVersionID}`
      );
    }
  }, [selectedPolicyVersion]);

  return (
    <>
      {documentMetadata && (
        <header className="grid gap-5">
          {isVisible ? (
            documentStatus?.status === "failed" ? (
              <article className={toastBorderColors.warning}>
                <FontAwesomeIcon icon={faWarning} className="text-red-500" />
                <p>Error processing your document!</p>
                <button onClick={() => setVisible(!isVisible)}>
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </article>
            ) : (
              documentStatus?.status === "parsing" && (
                <article className={toastBorderColors.info}>
                  <FontAwesomeIcon icon={faInfo} className="text-red-500" />
                  <p>Uno is currently processing the document!</p>
                  <button onClick={() => setVisible(!isVisible)}>
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </article>
              )
            )
          ) : null}
          <article className="flex items-center justify-between gap-20">
            <article className="grid content-start gap-1 w-full">
              <header className="flex flex-wrap items-start justify-between gap-10">
                <article className="grid gap-1">
                  <article className="flex flex-wrap items-start gap-10">
                    {editPolicyName ? (
                      <section className="flex items-center gap-2">
                        <button onClick={handleEditPolicyName}>
                          <FontAwesomeIcon
                            icon={faCheck}
                            className="text-no hover:text-no/60 duration-100"
                          />
                        </button>
                        <article className="relative grid gap-2">
                          <input
                            type="input"
                            spellCheck="false"
                            autoComplete="off"
                            value={policyName}
                            onBlur={handleEditPolicyName}
                            onChange={(e) => setPolicyName(e.target.value)}
                            className="px-4 py-1 md:w-[30rem] text-xl dark:bg-filter/30 focus:outline-none"
                          />
                        </article>
                      </section>
                    ) : isPolicy ? (
                      <button
                        className="py-1 break-words text-left text-2xl dark:text-checkbox dark:disabled:hover:bg-transparent dark:hover:bg-filter/30 duration-100 rounded-sm"
                        onClick={() => {
                          setEditPolicyName(true);
                          setPolicyName(documentMetadata.policy_name);
                        }}
                      >
                        {documentMetadata.policy_name}
                      </button>
                    ) : (
                      <h4 className="py-1 break-words text-left text-t1-semi">
                        {documentMetadata.framework_name}
                      </h4>
                    )}
                    <article className="flex items-center gap-10">
                      {documentMetadata?.policy_name && (
                        <>
                          <TextFilter
                            label="Version"
                            list={versions}
                            value={selectedPolicyVersion}
                            setValue={setSelectedPolicyVersion}
                          />
                          <UpdatePolicyVersion
                            documentName={documentName}
                            documentID={documentID}
                            setSelectedPolicyVersion={setSelectedPolicyVersion}
                          />
                        </>
                      )}
                    </article>
                  </article>
                  <article className="flex flex-wrap items-center gap-3 text-b2-reg dark:text-gray-400 divide-x dark:divide-gray-400">
                    {documentMetadata.last_updated_at && (
                      <span>
                        {convertToUTCShortString(
                          documentMetadata.last_updated_at
                        )}
                      </span>
                    )}
                    {documentMetadata.regulatory_date && (
                      <span>
                        {convertToUTCShortString(
                          documentMetadata.regulatory_date
                        )}
                      </span>
                    )}
                    {(documentMetadata.regulatory_authority ||
                      documentMetadata.customer_name) && (
                      <span className="pl-3">
                        {documentMetadata.regulatory_authority ||
                          documentMetadata.customer_name}
                      </span>
                    )}
                  </article>
                </article>
                <DocumentSearch
                  search={search}
                  query={query}
                  setQuery={setQuery}
                  setSearch={setSearch}
                />
              </header>
            </article>
            {documentMetadata.policy_name &&
              documentStatus?.status === "ready" && (
                <article className="flex items-center gap-5">
                  <DeletePolicy versionID={versionID} />
                </article>
              )}
          </article>
          {isPolicy && policyVersions?.length > 1 && (
            <article className="flex items-center gap-5">
              <a
                href={`/regulation-policy/document/policy-drift/details?policy_id=${documentID}&policy_name=${documentName}`}
                className="flex items-center gap-2 px-4 py-1 dark:hover:bg-filter/30 duration-100 rounded-full"
              >
                Policy Drift
                <FontAwesomeIcon icon={faArrowRightLong} />
              </a>
              <a
                href={`/regulation-policy/document/policy-drift-risk-assessment/details?policy_id=${documentID}&policy_name=${documentName}`}
                className="flex items-center gap-2 px-4 py-1 dark:hover:bg-filter/30 duration-100 rounded-full"
              >
                Policy Drift Risk Assessment
                <FontAwesomeIcon icon={faArrowRightLong} />
              </a>
            </article>
          )}
          {!isPolicy && (
            <>
              <RegionsVerticals
                documentType={documentType}
                documentID={documentID}
              />
              <DocumentTags
                documentType={documentType}
                documentID={documentID}
              />
            </>
          )}
        </header>
      )}
    </>
  );
};

export default DocumentMetadata;
