/* eslint-disable react-hooks/exhaustive-deps */
import {
  faCheckCircle,
  faInfoCircle,
  faTriangleExclamation,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect } from "react";
import { toastBorderColors, toastIconColors } from "../constants/general";

const ToastLayout: React.FC<{
  showModal: boolean;
  onClose: any;
  type: string;
  message: string;
}> = ({ showModal, onClose, type, message }) => {
  useEffect(() => {
    const handleEscape = (event: {
      key: string;
      preventDefault: () => void;
    }) => {
      if (event.key === "Escape") {
        event.preventDefault();
        onClose();
      }
    };
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  useEffect(() => {
    if (showModal) {
      const timer = setTimeout(() => {
        onClose();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showModal]);

  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog
        as="article"
        className="fixed inset-0 overflow-auto scrollbar z-50"
        onClose={onClose}
        onClick={(e: { stopPropagation: () => void }) => e.stopPropagation()}
      >
        <article className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 z-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <section className="inline-block mx-auto">
              <section className={`${toastBorderColors[type]}`}>
                <FontAwesomeIcon
                  icon={
                    type === "success"
                      ? faCheckCircle
                      : type === "info"
                      ? faInfoCircle
                      : faTriangleExclamation
                  }
                  className={toastIconColors[type]}
                />
                {message}
                <button
                  className="justify-self-end dark:text-white dark:hover:text-gray-700/70 duration-100"
                  onClick={onClose}
                >
                  <FontAwesomeIcon icon={faXmark} className="w-4 h-4" />
                </button>
              </section>
            </section>
          </Transition.Child>
        </article>
      </Dialog>
    </Transition>
  );
};

export default ToastLayout;
