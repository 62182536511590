/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  GetControlEvidence,
  GetEvidenceReport,
} from "src/services/audit-management";
import EvidenceDetail from "./EvidenceDetail";
import { GetAllUsers } from "src/services/settings/users";
import { getCustomerID } from "src/utils/general";
import { KeyStringVal } from "src/types/general";
import AttachEvidence from "./AttachEvidence";

const EvidenceList = ({
  auditID,
  controlID,
}: {
  auditID: string;
  controlID: string;
}) => {
  const customerID = getCustomerID();

  const { data: evidenceList } = GetControlEvidence(auditID, controlID);
  const { data: evidenceReport } = GetEvidenceReport(auditID, controlID);
  const { data: users } = GetAllUsers(customerID, false);

  return (
    <>
      <section className="grid gap-5">
        <article className="flex flex-wrap flex-grow items-center gap-5 p-4 text-b2-reg dark:bg-gray-800 rounded-lg">
          <h4 className="text-b1-semi">Evidences ({evidenceList?.length})</h4>
          {evidenceList?.length > 0 && (
            <ul className="flex flex-wrap gap-2">
              {evidenceList.map((evidence: any) => {
                return (
                  <EvidenceDetail
                    key={evidence.evidence_id}
                    auditID={auditID}
                    controlID={controlID}
                    evidence={evidence}
                  />
                );
              })}
            </ul>
          )}
          <AttachEvidence auditID={auditID} controlID={controlID} />
        </article>

        {evidenceReport && (
          <>
            {evidenceReport.users?.length > 0 && (
              <article className="flex flex-wrap flex-grow items-center gap-5 p-4 text-b2-reg dark:bg-gray-800 rounded-lg">
                <h4 className="text-b1-semi">Assigned to</h4>
                {evidenceReport.users?.length > 0 && (
                  <ul className="flex flex-wrap gap-2">
                    {evidenceReport.users.map((userID: string) => {
                      const userEmail = users?.find(
                        (user: KeyStringVal) => user.user_id === userID
                      )?.user_email;
                      return (
                        <li
                          key={userID}
                          className="px-2 py-1 text-b1-reg dark:bg-gray-700 rounded"
                        >
                          <span>{userEmail}</span>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </article>
            )}
            <article className="flex flex-col flex-grow gap-3 p-4 dark:bg-gray-800 rounded-lg">
              <h4 className="text-b1-semi">Notes</h4>
              <p className="text-b1-reg dark:text-gray-300">
                {evidenceReport.instructions}
              </p>
            </article>
          </>
        )}
      </section>
    </>
  );
};

export default EvidenceList;
