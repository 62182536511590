/* eslint-disable react-hooks/exhaustive-deps */
import DatePicker from "react-datepicker";
import { forwardRef, LegacyRef, RefObject, useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { utcFormat } from "d3-time-format";
import {
  convertUTCToLocalDate,
  convertLocalToUTCDate,
  convertToUTCString,
  convertToMicrosec,
  convertToDate,
} from "../../utils/general";
import { useGraphStore } from "../../stores/graph";
import React from "react";

const GeneralTemporalDatepicker = ({
  temporalStartDate,
  setTemporalStartDate,
  temporalEndDate,
  setTemporalEndDate,
  earliest,
  latest,
  ignoreWarning,
  required,
}: {
  temporalStartDate: Date;
  setTemporalStartDate: (temporalStartDate: Date) => void;
  temporalEndDate: Date;
  setTemporalEndDate: (temporalEndDate: Date) => void;
  earliest?: number;
  latest?: number;
  ignoreWarning?: boolean;
  required?: boolean;
}) => {
  const { setGraphSearching } = useGraphStore();

  const [startDateSelected, setStartDateSelected] = useState<boolean>(false);
  const [endDateSelected, setEndDateSelected] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const [apply, setApply] = useState<boolean>(false);

  const earliestDate = earliest ? convertToDate(earliest) : new Date();
  const latestDate = latest ? convertToDate(latest) : new Date();

  useEffect(() => {
    if (temporalStartDate > temporalEndDate)
      setTemporalStartDate(temporalEndDate);
    if (temporalStartDate > temporalEndDate)
      setTemporalEndDate(temporalStartDate);
  }, [temporalStartDate, temporalEndDate]);

  useEffect(() => {
    if (earliestDate && latestDate) {
      setTemporalStartDate(earliestDate);
      setTemporalEndDate(latestDate);
    }
  }, []);

  return (
    <section className="grid content-start text-b2-reg">
      <header className="flex items-center gap-1">
        <h4>Time Range</h4>
        {required && <span className="text-h5 text-red-500">*</span>}
      </header>
      <article className="relative flex items-center gap-3 px-4 py-2 w-full select-none dark:bg-gray-900 rounded">
        {/* from date */}
        <article className="relative flex items-center w-full">
          <DatePicker
            selected={convertUTCToLocalDate(temporalStartDate)}
            onChange={(date: Date) => {
              setGraphSearching(true);
              setSelected(false);
              setApply(false);
              setStartDateSelected(true);
              setTemporalStartDate(convertLocalToUTCDate(date) as Date);
            }}
            selectsStart
            // startDate={convertUTCToLocalDate(temporalStartDate)}
            // endDate={convertUTCToLocalDate(temporalEndDate)}
            showTimeSelect
            timeFormat="h:mm aa"
            timeIntervals={60}
            dateFormat="MMMM d, yyyy h:mm aa"
            placeholderText="From"
            nextMonthButtonLabel=">"
            previousMonthButtonLabel="<"
            wrapperClassName="datepicker"
            popperClassName="react-datepicker-left"
            // includeDateIntervals={[
            //   {
            //     start: convertUTCToLocalDate(earliestDate) as Date,
            //     end: convertUTCToLocalDate(latestDate) as Date,
            //   },
            // ]}
            customInput={
              <StartInput
                selected={selected}
                apply={apply}
                temporalStartDate={temporalStartDate}
                startDateSelected={startDateSelected}
              />
            }
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <article className="flex items-center justify-between px-2 py-2 dark:text-white">
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  type="button"
                  className={`${
                    prevMonthButtonDisabled
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  } inline-flex text-xs font-medium shadow-sm dark:hover:bg-checkbox focus:outline-none rounded`}
                >
                  <ChevronLeftIcon className="w-5 h-5" />
                </button>
                <p className="text-sm">
                  {utcFormat("%B")(date)} <span>{utcFormat("%Y")(date)}</span>
                </p>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  type="button"
                  className={`${
                    nextMonthButtonDisabled
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  } inline-flex text-xs font-medium shadow-sm dark:hover:bg-checkbox focus:outline-none rounded`}
                >
                  <ChevronRightIcon className="w-5 h-5" />
                </button>
              </article>
            )}
          />
        </article>

        <FontAwesomeIcon
          icon={faArrowRightLong}
          className="w-3 h-3 dark:text-gray-500"
        />

        {/* to date */}
        <article className="relative flex items-center w-full">
          <DatePicker
            selected={convertUTCToLocalDate(temporalEndDate)}
            onChange={(date: Date) => {
              setGraphSearching(true);
              setSelected(false);
              setApply(false);
              setEndDateSelected(true);
              setTemporalEndDate(convertLocalToUTCDate(date) as Date);
            }}
            selectsEnd
            // startDate={convertUTCToLocalDate(temporalStartDate)}
            // endDate={convertUTCToLocalDate(temporalEndDate)}
            showTimeSelect
            timeFormat="h:mm aa"
            timeIntervals={60}
            dateFormat="MMMM d, yyyy h:mm aa"
            placeholderText="To"
            nextMonthButtonLabel=">"
            previousMonthButtonLabel="<"
            wrapperClassName="datepicker"
            popperClassName="react-datepicker-right"
            // includeDateIntervals={[
            //   {
            //     start: convertUTCToLocalDate(earliestDate) as Date,
            //     end: convertUTCToLocalDate(latestDate) as Date,
            //   },
            // ]}
            customInput={
              <EndInput
                selected={selected}
                apply={apply}
                temporalEndDate={temporalEndDate}
                endDateSelected={endDateSelected}
              />
            }
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <article className="flex items-center justify-between px-2 py-2 dark:text-white">
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  type="button"
                  className={`${
                    prevMonthButtonDisabled
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  } inline-flex text-xs font-medium shadow-sm dark:hover:bg-checkbox focus:outline-none rounded`}
                >
                  <ChevronLeftIcon className="w-5 h-5" />
                </button>
                <p className="text-sm">
                  {utcFormat("%b")(date)} <span>{utcFormat("%Y")(date)}</span>
                </p>
                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  type="button"
                  className={`${
                    nextMonthButtonDisabled
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  } inline-flex text-xs font-medium shadow-sm dark:hover:bg-checkbox focus:outline-none rounded`}
                >
                  <ChevronRightIcon className="w-5 h-5" />
                </button>
              </article>
            )}
          />
        </article>
      </article>
    </section>
  );
};

export default GeneralTemporalDatepicker;

const StartInput = forwardRef(
  ({ onClick, temporalStartDate, startDateSelected }: any, ref) => (
    <button
      onClick={onClick}
      ref={ref as RefObject<HTMLButtonElement>}
      className={`w-full text-left text-b2-reg ${
        startDateSelected ? "dark:text-white" : "dark:text-gray-500"
      }`}
    >
      {!startDateSelected
        ? "Start Date"
        : convertToUTCString(convertToMicrosec(temporalStartDate))}
    </button>
  )
);

const EndInput = forwardRef(
  (
    { onClick, temporalEndDate, endDateSelected }: any,
    ref: LegacyRef<HTMLButtonElement> | undefined
  ) => (
    <button
      onClick={onClick}
      ref={ref}
      className={`w-full text-left text-b2-reg ${
        endDateSelected ? "dark:text-white" : "dark:text-gray-500"
      }`}
    >
      {!endDateSelected
        ? "End Date"
        : convertToUTCString(convertToMicrosec(temporalEndDate))}
    </button>
  )
);
