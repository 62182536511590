import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import SelectFrameworkFilter from "src/components/Filter/RegulationPolicy/SelectFrameworkFilter";
import { GetFrameworksWithControls } from "src/services/regulation-policy/framework";
import { AddAudit } from "src/services/audit-management";
import RegularInput from "src/components/Input/RegularInput";
import ToastLayout from "../../layouts/ToastLayout";

const NewAudit = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState({
    name: "",
  });
  const [selectedFramework, setSelectedFramework] = useState<any>({
    id: "",
    name: "",
  });

  const { data: frameworksWithControls } = GetFrameworksWithControls();
  const addAudit = AddAudit();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addAudit.reset();

  return (
    <>
      <button
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
          });
          setSelectedFramework({ id: "", name: "" });
        }}
      >
        <FontAwesomeIcon icon={faUpload} />
        <h4>Start Internal Audit</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Start Internal Audit
          </h3>
          <RegularInput
            label="Name"
            keyName="name"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <article className="grid gap-2">
            <h4>Framework</h4>
            <SelectFrameworkFilter
              selectedFramework={selectedFramework}
              setSelectedFramework={setSelectedFramework}
              list={frameworksWithControls?.data}
            />
          </article>
          <article className="flex items-center place-content-end gap-5">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={selectedFramework.id === "" || inputs.name === ""}
              className="blue-button"
              onClick={() => {
                addAudit.mutate({
                  name: inputs.name,
                  framework_id: selectedFramework.id,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addAudit.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New audit has been created"
      />
    </>
  );
};

export default NewAudit;
