import React, { useEffect, useState } from "react";
import NewReview from "./NewAudit";
import TablePagination from "src/components/General/TablePagination";
import Loader from "src/components/Loader/Loader";
import { pageSize } from "src/constants/general";
import { useNavigate } from "react-router-dom";
import { GetAuditList } from "src/services/audit-management";
import PageLayout from "../../layouts/PageLayout";
import {
  attributeColors,
  initialSort,
  showVariants,
} from "../../constants/general";
import { motion } from "framer-motion";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortRows, handleSort, lastUpdatedAt } from "../../utils/general";
import TableLayout from "src/layouts/TableLayout";

const AuditManagement = () => {
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState<Sort>(initialSort);

  const { data: auditList, status: auditStatus } = GetAuditList(pageNumber);

  const metadata = auditList && JSON.parse(auditList.header.metadata);
  const filteredMetadata = metadata?.required.filter((col: string) => {
    if (metadata.properties[col].hidden === "True") return null;
    return col;
  });
  const sortedRows = auditList && sortRows(auditList?.data, sort);

  const totalCount = auditList?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  useEffect(() => {
    sessionStorage.page = "Audit Management";
  }, []);

  return (
    <PageLayout>
      <motion.main
        variants={showVariants}
        initial="hidden"
        animate="visible"
        className="flex flex-col flex-grow gap-5 p-4 w-full h-full text-base overflow-auto scrollbar"
      >
        {auditList?.data.length > 0 && <NewReview />}
        {auditStatus === "loading" ? (
          <Loader />
        ) : auditList?.data.length > 0 ? (
          <section className="flex flex-col flex-grow gap-5 pb-20">
            <TableLayout fullHeight>
              <thead className="sticky -top-1.5 dark:bg-gray-900">
                <tr>
                  {filteredMetadata?.map((col: string) => {
                    const column = metadata.properties[col];
                    return (
                      <th
                        scope="col"
                        key={col}
                        className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                      >
                        <article className="capitalize flex gap-10 justify-between">
                          <h4 className="break-words">{column.title}</h4>
                          <button aria-label="sort">
                            <FontAwesomeIcon
                              icon={faSort}
                              className="mt-0.5 dark:text-gray-700"
                              onClick={() => handleSort(col, setSort)}
                            />
                          </button>
                        </article>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="divide-y dark:divide-gray-700">
                {sortedRows?.map((row: any, index: number) => {
                  return (
                    <tr
                      key={index}
                      data-test="table-row"
                      className="relative p-5 gap-3 cursor-pointer break-words dark:bg-gray-800 dark:hover:bg-gray-800/70 duration-100"
                      onClick={() =>
                        navigate(
                          `/audit-management/audit/details?audit_id=${row.audit_id}&framework_id=${row.framework_id}`
                        )
                      }
                    >
                      {filteredMetadata?.map(
                        (col: string, colIndex: number) => {
                          const date = lastUpdatedAt(row.created_at);

                          return (
                            <td
                              key={`${index}-${colIndex}`}
                              className="relative px-6 py-3 last:pr-16 text-left break-words"
                            >
                              <p
                                className={`${
                                  attributeColors[
                                    String(row[col]).toLowerCase()
                                  ]
                                }`}
                              >
                                {col === "created_at" ? date : String(row[col])}
                              </p>
                            </td>
                          );
                        }
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </TableLayout>
            <TablePagination
              totalPages={totalPages}
              beginning={beginning}
              end={end}
              totalCount={totalCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </section>
        ) : (
          <section className="flex items-center place-content-center gap-10 w-full h-full">
            <img
              src="/grc/internal-audit-placeholder.svg"
              alt="audit management placeholder"
              className="w-40 h-40"
            />
            <article className="grid gap-3">
              <h4 className="text-t1-bold">Internal Audits</h4>
              <h4>No internal audits available</h4>
              <NewReview />
            </article>
          </section>
        )}
      </motion.main>
    </PageLayout>
  );
};

export default AuditManagement;
