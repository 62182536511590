import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

const FilterLayout = ({
  label,
  showOnLeft,
  children,
}: {
  label: string;
  showOnLeft?: boolean;
  children: any;
}) => {
  return (
    <Popover className="relative text-b2-reg">
      <Popover.Button>
        <article className="flex items-center gap-2 px-4 py-2 w-max dark:bg-gray-900 border dark:border-gray-700 divide-x dark:divide-gray-700 rounded">
          <h4>{label}</h4>
          <FontAwesomeIcon
            icon={faFilter}
            className="pl-2 dark:text-blue-600"
          />
        </article>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          className={`absolute ${
            showOnLeft ? "right-0" : "left-0"
          } top-12 z-10`}
        >
          {({ close }) => (
            <section className="grid gap-1 p-4 w-full max-h-[30rem] dark:bg-gray-700 black-shadow overflow-auto scrollbar rounded-lg">
              {children}
            </section>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default FilterLayout;
