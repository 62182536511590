/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import { MutableRefObject, useEffect, useRef, useState } from "react";
import OTPInput from "../Login/TwoFA/OTPInput";
import SessionLayout from "../../layouts/SessionLayout";
import {
  convertToUTCShortString,
  convertToUTCString,
  parseURL,
} from "../../utils/general";
import React from "react";
import TablePagination from "../../components/General/TablePagination";
import { pageSize } from "../../constants/general";
import jwt_decode from "jwt-decode";
import AttachEvidence from "./AttachEvidence";
import {
  RetrieveJWTForVendor,
  RetrieveVendorQuestions,
  SubmitQuestions,
  SaveDrafts,
  UploadAdditionalDocs,
} from "src/services/vendor-webform";
import UploadAdditionalDocuments from "./UploadAdditionalDocuments";
import AllTags from "./AllTags";
import { faCheck, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

const VendorWebform = () => {
  const parsed = parseURL();
  const vendorID = String(parsed.third_party_id);
  const assessmentID = String(parsed.assessment_id);
  const vendorJwt = sessionStorage.vendorJwt
    ? (jwt_decode(sessionStorage.vendorJwt) as any)
    : "";

  const inputRef = useRef([]) as MutableRefObject<HTMLInputElement[]>;

  const [otpCode, setOTPCode] = useState<string>("");
  const [nav, setNav] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [responses, setResponses] = useState({});
  const [additionalDocs, setAdditionalDocs] = useState<any>({
    files: [],
    document_types: [],
    frameworks: [],
  });
  const [inputs, setInputs] = useState<any>({
    files: [],
    evidence_file: {},
  });
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [saved, setSaved] = useState<boolean>(false);

  const retrieveJWT = RetrieveJWTForVendor(vendorID, assessmentID);
  const { data: questions } = RetrieveVendorQuestions(
    pageNumber,
    vendorJwt,
    selectedTags
  );
  const submitResponses = SubmitQuestions();
  const saveDrafts = SaveDrafts();
  const uploadDocuments = UploadAdditionalDocs();

  const totalCount = questions?.pager?.total_results || 0;
  const totalPages = questions?.pager?.num_pages || 0;
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  useEffect(() => {
    if (otpCode.length === 6) {
      const formData = new FormData();
      formData.append("hotp_code", String(otpCode));
      retrieveJWT.mutate(
        {
          formData,
        },
        {
          onSuccess: (data) => {
            if (data) {
              setNav(2);
              sessionStorage.vendorJwt = data;
            } else {
              setOTPCode("");
              if (inputRef?.current) inputRef?.current[0]?.focus();
            }
          },
        }
      );
    }
  }, [otpCode]);

  useEffect(() => {
    if (questions?.data?.length > 0) {
      let updatedResponses = {};
      questions?.data.forEach((qa) => {
        updatedResponses = {
          ...updatedResponses,
          [qa.question_id]: qa.answer,
        };
      });
      setResponses(updatedResponses);
    }
  }, [questions, pageNumber]);

  return (
    <SessionLayout>
      <h4 className="text-t1-semi">Vendor Assessment</h4>

      {/* if current time passes the expiration time  */}
      {vendorJwt?.scope?.expired ||
      Date.now() * 1000 > vendorJwt?.scope?.end_date ? (
        <section className="grid py-10 gap-3 text-center dark:bg-gray-900">
          <p>The link has expired......</p>
          <p>Check with your customer to send a new email.</p>
        </section>
      ) : nav === 1 ? (
        <section className="grid content-start gap-5 p-10 dark:bg-gray-900">
          <p className="text-xl tracking wide">Enter OTP</p>

          {retrieveJWT.status === "success" && !retrieveJWT.data && (
            <p className="p-2 mx-auto w-max text-xs text-left uppercase dark:bg-inner border dark:border-error rounded-sm">
              OTP code is not correct. Please try again.
            </p>
          )}

          <OTPInput
            otpCode={otpCode}
            setOTPCode={setOTPCode}
            codeLength={6}
            inputRef={inputRef}
          />
        </section>
      ) : nav === 2 ? (
        <section className="flex flex-col flex-grow gap-5">
          {vendorJwt && vendorJwt.scope && (
            <header className="flex flex-col flex-grow gap-5 text-b1-reg text-left">
              <article className="grid gap-2">
                <h4 className="text-t1-reg">Assesssment</h4>
                <article className="flex flex-wrap items-center justify-between gap-20 pb-2">
                  <h2 className="text-t1-reg">
                    {vendorJwt.scope.assessment_name}
                  </h2>
                  <article className="flex flex-wrap items-center gap-3">
                    {convertToUTCShortString(vendorJwt.scope.start_date)} -{" "}
                    {convertToUTCShortString(vendorJwt.scope.end_date)}
                  </article>
                </article>
              </article>
              <article className="grid gap-2">
                <h4 className="text-t1-reg">Vendor</h4>
                <span>{vendorJwt.scope.vendor_name}</span>
              </article>
            </header>
          )}
          <h4>Vendor Questions</h4>
          <span className="text-b3-reg">
            Please fill out the response for each question
          </span>
          <UploadAdditionalDocuments
            inputs={additionalDocs}
            setInputs={setAdditionalDocs}
          />
          <AllTags
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
          <ul className="flex flex-col flex-grow gap-5">
            {questions?.data?.map((qa: any) => {
              const questionID = qa.question_id;
              return (
                <li
                  key={questionID}
                  className="grid gap-2 p-5 w-full dark:bg-gray-800 rounded"
                >
                  {qa.tags?.length > 0 && (
                    <article className="flex flex-wrap items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-gray-700 rounded">
                      <span>Tags</span>
                      {qa.tags.map((tag: string, index: number) => {
                        return (
                          <span key={index} className="dark:text-blue-500">
                            {tag}
                          </span>
                        );
                      })}
                    </article>
                  )}
                  <p className="text-left"> Q: {qa.question}</p>
                  {qa.question_type === "Yes_No" ? (
                    <section className="flex items-center gap-5">
                      {["Yes", "No"].map((questionType) => {
                        return (
                          <article
                            key={questionType}
                            className="flex items-center gap-1"
                          >
                            <input
                              type="radio"
                              className="form-radio w-4 h-4 dark:bg-transparent dark:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50 rounded-full"
                              checked={responses[questionID] === questionType}
                              onChange={() =>
                                setResponses({
                                  ...responses,
                                  [questionID]: questionType,
                                })
                              }
                            />
                            <label htmlFor="">
                              {questionType === "Yes_No" ? "Y/N" : questionType}
                            </label>
                          </article>
                        );
                      })}
                    </section>
                  ) : (
                    <article className="flex items-start gap-2 w-full">
                      A:{" "}
                      <textarea
                        value={responses[questionID]}
                        onChange={(e) =>
                          setResponses({
                            ...responses,
                            [questionID]: e.target.value,
                          })
                        }
                        className="px-4 py-2 w-full h-32 focus:outline-none text-b2-reg placeholder:text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 resize-none rounded"
                      />
                    </article>
                  )}
                  <section className="flex flex-wrap items-center gap-3">
                    <article className="flex flex-wrap flex-grow items-center gap-5 p-4 text-b2-reg dark:bg-gray-800 rounded-lg">
                      <h4 className="text-b1-semi">Evidences</h4>
                      {inputs.evidence_file[questionID]?.length > 0 && (
                        <ul className="flex flex-wrap gap-2">
                          {inputs.evidence_file[questionID].map(
                            (fileName: string, idx: number) => {
                              return (
                                <li
                                  key={idx}
                                  className="flex items-center gap-2 px-2 py-1 text-b1-reg dark:bg-gray-700 rounded"
                                >
                                  <FontAwesomeIcon
                                    icon={faLink}
                                    className="dark:text-blue-500"
                                  />
                                  <h4>{fileName}</h4>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      )}
                    </article>
                    <AttachEvidence
                      questionID={questionID}
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                  </section>
                </li>
              );
            })}
          </ul>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
          {saved && (
            <motion.article
              initial={{ y: 3, opacity: 0 }}
              animate={{
                y: 0,
                opacity: 1,
                transition: { duration: 0.3 },
              }}
              className="flex items-center gap-2 text-xs"
            >
              <FontAwesomeIcon
                icon={faCheck}
                className="w-3 h-3 dark:text-contact"
              />
              Successfully saved
            </motion.article>
          )}
          <article className="flex items-center place-self-center gap-5">
            {Object.entries(responses).some((keyVal: any) => keyVal[1]) && (
              <button
                disabled={saveDrafts.status === "loading"}
                className="place-self-end blue-button"
                onClick={() => {
                  const formData = new FormData();

                  if (inputs.files.length > 0)
                    inputs.files.forEach((file: any) =>
                      formData.append("files", file)
                    );
                  formData.append(
                    "evidence_metadata",
                    JSON.stringify(inputs.evidence_file)
                  );
                  formData.append("questions", JSON.stringify(responses));

                  saveDrafts.mutate(
                    {
                      formData,
                    },
                    {
                      onSuccess: () => {
                        setSaved(true);
                        setTimeout(() => {
                          setSaved(false);
                        }, 5000);
                      },
                    }
                  );
                }}
              >
                Save Draft
              </button>
            )}
            <button
              disabled={submitResponses.status === "loading"}
              className="blue-button"
              onClick={() => {
                const formData = new FormData();

                if (inputs.files.length > 0)
                  inputs.files.forEach((file: any) =>
                    formData.append("files", file)
                  );
                formData.append(
                  "evidence_metadata",
                  JSON.stringify(inputs.evidence_file)
                );
                formData.append("questions", JSON.stringify(responses));

                const additionalFormData = new FormData();

                if (additionalDocs.files.length > 0)
                  additionalDocs.files.forEach((file: any) =>
                    additionalFormData.append("files", file)
                  );
                if (additionalDocs.document_types.length > 0)
                  additionalDocs.document_types.forEach((documentType: any) =>
                    additionalFormData.append("document_types", documentType)
                  );
                if (additionalDocs.frameworks.length > 0)
                  additionalDocs.frameworks.forEach((framework: any) =>
                    additionalFormData.append("frameworks", framework)
                  );

                if (additionalDocs.files.length > 0)
                  uploadDocuments.mutate({
                    formData: additionalFormData,
                  });

                submitResponses.mutate(
                  {
                    formData,
                  },
                  {
                    onSuccess: () => setNav(3),
                  }
                );
              }}
            >
              Submit
            </button>
          </article>
        </section>
      ) : nav === 3 ? (
        <section className="grid content-start p-10 gap-5 w-full h-full text-b2-reg text-center dark:bg-gray-800">
          <h4 className="text-b1-reg">{vendorJwt?.scope?.vendor_name}</h4>
          <p>
            You have successfully submitted the vendor assesssment! You can
            close this browser now.
          </p>
          <span className="text-b3-reg">
            Submitted at: {convertToUTCString(Date.now() * 1000)}
          </span>
        </section>
      ) : null}
    </SessionLayout>
  );
};

export default VendorWebform;
